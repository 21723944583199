'use client';

import { useEffect, useMemo } from 'react';
import Image from 'next/image';
import { RenderNode, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { width } from 'tailwindcss/defaultTheme';
import { Typography } from 'components/atoms/typography';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { getCookie, parseCookieGroupPermissions } from 'helpers/utils/cookies';
import { useAppSelector } from 'redux/hooks';
import { setCookiePermissions } from 'redux/reducers/cookies';
import { store } from 'redux/store';
import {
  BannerSection,
  FaqSection,
  ImageGallerySection,
  ImageSection,
  SourcesSection,
} from './contentful-render-component';
import { withBlockQuotes, withListItem, withParagraph } from './contentful-render-node-hoc';
import {
  BannerPadding,
  BottomPaddingClass,
  CommonIframeTag,
  CommontHtmlTag,
  HeadingStyling,
  ImageContainer,
  ImageDescriptionStyle,
  RichTextContainer,
  VideoContainer,
} from './style';

const CommonHtmlTag = (entry, width) => {
  const { tag: HtmlTag, content, cssClassName, cssId, videoCaption } = entry;
  const cookiePermissions = store?.getState().cookiesReducer.cookieGroups;
  const memoizedCookie = useMemo(() => {
    return getCookie('OptanonConsent');
  }, [typeof document !== 'undefined' ? document?.cookie : null]);
  const oneTrustCookie = typeof document !== 'undefined' ? memoizedCookie : null;

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const cookiePermissions = parseCookieGroupPermissions(oneTrustCookie as string);
      store.dispatch(setCookiePermissions({ cookieGroups: cookiePermissions as string }));
    }
  }, [oneTrustCookie]);

  const HtmlContent = () => (
    <HtmlTag className={cssClassName} id={cssId}>
      <ContentfulRichText data={content} />
    </HtmlTag>
  );

  if (HtmlTag.toLowerCase() === 'iframe') {
    if (cookiePermissions?.includes('C0005:0')) {
      return <div className="text-red-400">Please enable Social Media Cookies in preference center.</div>;
    } else {
      const videoContent = content?.json?.content[0].content[0].value || null;
      return (
        <VideoContainer data-testid={'video-embbed'}>
          <CommonIframeTag
            style={
              cssClassName && cssClassName.includes('video')
                ? { height: width < 840 ? (cssClassName.includes('video-16/9') ? 9 / 16 : 3 / 4) * width - 60 : 440 }
                : {}
            }
            $video={cssClassName && cssClassName.includes('video')}
            className={cssClassName && cssClassName.includes('video') ? '' : cssClassName}
            dangerouslySetInnerHTML={{ __html: videoContent && videoContent }}
          />
          {videoCaption && (
            <figcaption className={`${ImageDescriptionStyle} [&_p]:pb-0 [&_p]:text-sm  xl:[&_p]:text-base`}>
              <ContentfulRichText data={videoCaption} />
            </figcaption>
          )}
        </VideoContainer>
      );
    }
  }

  return cssClassName === 'recipe-subtitle' ? (
    <CommontHtmlTag variant="h5" as={'div'}>
      <HtmlContent />
    </CommontHtmlTag>
  ) : (
    <HtmlContent />
  );
};

const renderOptions = (
  links: any,
  renderNodeOptions: RenderNode,
  customisedComponents: ICustomisedComponents,
  host: string,
) => {
  const assetMap = new Map();

  enum EntryType {
    CommonHtmlTag = 'CommonHtmlTag',
    SectionFaq = 'SectionFaq',
    CommonHeroBanner = 'CommonHeroBanner',
    SectionCommonSection = 'SectionCommonSection',
    CommonImage = 'CommonImage',
    CommonImageGallery = 'CommonImageGallery',
  }

  const entryTypeMap = {
    [EntryType.CommonHtmlTag]: (entry, width) => CommonHtmlTag(entry, width),
    [EntryType.SectionFaq]: (entry) => FaqSection(entry),
    [EntryType.CommonHeroBanner]: (entry) => BannerSection(entry, BannerPadding + BottomPaddingClass),
    [EntryType.SectionCommonSection]: (entry) => SourcesSection(entry),
    [EntryType.CommonImage]: (entry) => ImageSection(entry),
    [EntryType.CommonImageGallery]: (entry) => ImageGallerySection(entry),
  };

  // loop through the assets and add them to the map
  if (links?.assets?.block) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and add them to the map
  if (links?.entries?.block) {
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry);
    }
  }
  // loop through the inline linked entries and add them to the map
  if (links?.entries?.inline) {
    for (const entry of links?.entries?.inline) {
      entryMap.set(entry?.sys?.id, entry);
    }
  }

  return {
    // other options...
    renderText: (text) => {
      if (!Boolean(text.trim())) return text;
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1" className={HeadingStyling}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h4" as={'h2'} className={HeadingStyling}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h5" as={'h3'} className={HeadingStyling}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography variant="h4" className={HeadingStyling}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography variant="h5" className={HeadingStyling}>
          {children}
        </Typography>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => withListItem(node, children),
      [BLOCKS.QUOTE]: (node, children) => withBlockQuotes(node, children),
      [BLOCKS.PARAGRAPH]: (node, children) => withParagraph(node, children),
      [INLINES.HYPERLINK]: (node, children) => {
        const url = ReplaceUrlWithHost(node.data.uri, host);

        return (
          <Typography
            variant="body-large"
            as={'a'}
            target={!url?.includes(host) ? '_blank' : '_self'}
            rel={!url?.includes(host) ? 'nofollow' : ''}
            href={url}
          >
            {children}
          </Typography>
        );
      },

      // Usage:
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const entry = entryMap.get(node.data.target.sys.id);
        const entryType = entry?.__typename as EntryType;

        if (entryType && entryTypeMap[entryType]) {
          return entryTypeMap[entryType](entry, width);
        }
      },
      //
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = assetMap.get(node.data.target.sys.id);

        const entry = assetMap.get(node.data.target.sys.id);
        if (entry?.__typename === 'Assets') {
          return ImageSection(entry);
        }
        if (asset?.url) {
          if (customisedComponents?.EmbededAsset) return <customisedComponents.EmbededAsset image={asset} />;
          return (
            <ImageContainer className={BottomPaddingClass}>
              <Image
                src={asset.url}
                alt={asset.title}
                loader={(options) => options?.src}
                objectFit="contain"
                width={asset.width}
                height={asset.height}
              />
              {asset?.description && <figcaption>{asset.description}</figcaption>}
            </ImageContainer>
          );
        }
      },
      // other options...
      ...renderNodeOptions,
    },
  };
};

type TCommonCta = {
  title: string;
  url: string;
  image: IContentfulImage;
  openInNewTab: boolean;
};

type TEmbededAsset = {
  image: IContentfulImage;
};

interface ICustomisedComponents {
  CommonCta?: React.FC<TCommonCta>;
  EmbededAsset?: React.FC<TEmbededAsset>;
}

type TContentfulRichText = {
  data: IRichTextData;
  customisedComponents?: ICustomisedComponents;
  renderNodeOptions?: RenderNode;
};

// TODO: review this component
// Warning: <P /> is using incorrect casing. Use PascalCase for React components, or lowercase for HTML elements
export const ContentfulRichText = ({ data, customisedComponents, renderNodeOptions = {} }: TContentfulRichText) => {
  const host = useAppSelector((state) => state?.hostReducer?.host);

  if (!data?.json) return null;
  if (data?.json) {
    return (
      <RichTextContainer data-testid={'richtextcomponent'}>
        {documentToReactComponents(
          data.json,
          renderOptions(data.links, renderNodeOptions, customisedComponents as ICustomisedComponents, host),
        )}
      </RichTextContainer>
    );
  }
};
