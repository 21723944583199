import { useEffect, useMemo, useRef } from 'react';
import { Option, Select } from '@material-tailwind/react/components/Select';
import tw from 'tailwind-styled-components';
import { Typography } from 'components/atoms/typography';
import Alert from 'public/icons/figma/alert.svg';
import CheckIcon from 'public/icons/figma/check.svg';
export interface SelectDefaultProps {
  items: any[];
  required?: boolean;
  onChange?: (e: any) => any;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  success?: boolean;
  label?: string;
  defaultValue?: string;
}
interface SelectDropdownContainerProps {
  $error?: boolean;
  $disabled?: boolean;
}
// At this point of time. Theme is not styling button correctly. This wrapper div will styles the borders colors.
const SelectDropdownContainer = tw.div<SelectDropdownContainerProps>`
  ${(p) =>
    p.$error
      ? `
  inline-flex
  flex-col
  items-start
  gap-1
  [&_button]:!border-error
  [&_button[aria-expanded="true"]]:!border-error
  [&_label.active]:before:border-error
  `
      : p.$disabled
      ? `[&_button]:!border-grey-300 [&_labe]:!border-grey-300`
      : `
  [&_button]:!border-grey-480
  [&_button[aria-expanded="true"]]:!border-grey-400
  [&_label.active]:before:border-grey-400
  [&_label.active]:after:border-grey-400
  `}
`;

const ListStyle = `
  flex
  items-center
  gap-2
  rounded-none

  w-[350px]
  h-12
  p-[16px_44px_16px_16px]

  md:w-[420px]
  md:h-16
  md:p-[22px_56px_22px_20px]
  md:gap-0

  after:content-[""]
  after:block
  after:mt-0
  after:border-b
  after:border-grey-200

  after:mx-[-285px]
  after:w-[290px]
  after:pt-12

  md:after:-mx-[345px]
  md:after:w-[370px]
  md:after:pt-16
`;

const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <div className="flex w-full flex-row items-start">
      <Alert className="text-error" />
      <span className="w-full pl-0.5 pt-[5px] ">
        <Typography variant="body-small-bold">{message}</Typography>
      </span>
    </div>
  );
};

export const SelectDropdown = ({
  items = [],
  label = 'Select',
  required = false,
  onChange,
  error = false,
  errorMessage = 'Error Message',
  disabled = false,
  defaultValue,
  ...props
}: SelectDefaultProps) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current.lastChild.innerHTML = `
        <span class="select-label transition-all ease-in duration-100 px-1.5 h-[15px] ${
          disabled ? '!text-grey-300' : '!text-grey-400'
        }">
          ${ref.current.lastChild.innerHTML}
          ${required ? `<span class='text-resin'>*</span>` : ''}
        </span>
      `;
  }, []);

  useEffect(() => {
    if (ref?.current?.lastChild?.firstElementChild) {
      // label text
      ref.current.lastChild.firstElementChild.className = disabled
        ? ref.current.lastChild.firstElementChild.className?.replace('!text-grey-400', '!text-grey-300')
        : ref.current.lastChild.firstElementChild.className?.replace('!text-grey-300', '!text-grey-400');
      // label icon
      ref.current?.firstChild?.lastChild?.firstChild?.setAttribute('fill', disabled ? '#BDC2A6' : '#6F7263');
    }
  }, [disabled]);

  const options = useMemo(
    () =>
      items.map(({ label, value }) => (
        <Option className={ListStyle} key={label} value={value}>
          <span className="w-[290px] shrink-0 lg:w-[344px]">{label}</span>
          <CheckIcon className="hidden" />
        </Option>
      )),
    [items],
  );
  return (
    <SelectDropdownContainer $error={error} $disabled={disabled}>
      <Select
        key={`${disabled}`} // clear
        label={label}
        ref={ref}
        value={defaultValue}
        onChange={onChange}
        selected={(e: any) => {
          return disabled ? <></> : <>{e?.key || defaultValue}</>;
        }}
        menuProps={{
          className: `
            rounded-t-none
            p-0
            [&_li[data-selected="true"]_svg]:block
            [&_li[data-selected="true"]]:text-leaf
            [&_li[data-selected="true"]]:font-bold
            [&_li[data-selected="true"]_svg_path]:fill-leaf
            [&_li[data-selected="true"]]:after:mx-[-315px]
            lg:[&_li[data-selected="true"]]:after:mx-[-378px]
            border-grey-400
            !overflow-x-hidden
            `,
        }}
        disabled={disabled}
        error={error}
        {...props}
        data-testid={'select-dropdown'}
      >
        {options}
      </Select>
      {error ? <ErrorMessage message={errorMessage} /> : null}
    </SelectDropdownContainer>
  );
};
