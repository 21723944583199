'use client';

import { ComponentProps, ElementType, ReactNode } from 'react';
import { usePageType } from 'helpers/hooks/usePageType';
import { TAdditionalContext } from 'helpers/types';
import { MemoizedAutoIdFromAdditionalContext } from 'helpers/utils/common';
import { TypographyTag } from './styles';

export type fontFamily = 'arial' | 'antartica';

export type fontWeight = 'bold' | 'semibold' | 'regular' | 'thin';

export type colors =
  | 'light'
  | 'dark'
  | 'light-dark'
  | 'kief'
  | 'kief-900'
  | 'leaf-70'
  | 'leaf'
  | 'leaf-dark'
  | 'leaf-shadow'
  | 'resin'
  | 'grey-100'
  | 'grey-200'
  | 'grey-280'
  | 'grey-250'
  | 'grey-300'
  | 'grey-400'
  | 'grey-480'
  | 'grey-500'
  | 'grey-600'
  | 'grey-700'
  | 'grey-800'
  | 'grey-900'
  | 'error'
  | 'overlay'
  | 'hyperlink-100'
  | 'white-color-100'
  | 'white-color-200';

export type TTypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'body-large'
  | 'body-large-bold'
  | 'body-small'
  | 'body-small-bold'
  | 'body-small-bold-underline'
  | 'small';

type Props<T extends keyof JSX.IntrinsicElements> = ComponentProps<T>;
export type BaseTypographyProps = Props<'p'> &
  Props<'h1'> &
  Props<'h2'> &
  Props<'h3'> &
  Props<'h4'> &
  Props<'h5'> &
  Props<'h6'> &
  Props<'a'>;
export interface TProps extends BaseTypographyProps {
  color?: colors;
  variant?: TTypographyVariant;
  children: ReactNode;
  className?: string;
  fontFamily?: fontFamily;
  fontWeight?: fontWeight;
  as?: ElementType;
  'data-testid'?: string;
  additionalContext?: TAdditionalContext;
}

const tags: Record<TTypographyVariant, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  'body-large': 'p',
  'body-large-bold': 'p',
  'body-small': 'p',
  'body-small-bold': 'p',
  'body-small-bold-underline': 'p',
  small: 'p',
};

export const Typography = ({
  variant = 'body-large',
  children,
  className = '',
  color = 'leaf',
  fontFamily,
  fontWeight,
  as,
  'data-testid': testId,
  additionalContext,
  ...rest
}: TProps) => {
  const elementTypeTag = as || tags[variant];
  const pageType = usePageType();
  return (
    <TypographyTag
      $as={elementTypeTag}
      $variant={variant}
      $color={color}
      $fontFamily={fontFamily}
      $fontWeight={fontWeight}
      className={className}
      data-testid={testId || (additionalContext && additionalContext.testId) || null}
      data-automationid={MemoizedAutoIdFromAdditionalContext(
        additionalContext as TAdditionalContext,
        pageType as string,
      )}
      {...rest}
    >
      {children}
    </TypographyTag>
  );
};
