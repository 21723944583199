import { SegmentEvents } from '../constants/forms';
import { ICommunicationInitiated, SegementTrackEvent } from './segment-helper';

export const communicationInitiated = (value) => {
  try {
    const data: ICommunicationInitiated = {
      ...value,
    };
    return SegementTrackEvent(SegmentEvents.COMMUNICATION_INITIATED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
