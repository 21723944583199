import React from 'react';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import FooterLicense from 'components/organisms/global/footer/footer-license';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { useChatEventTracking } from 'helpers/hooks/use-chat-event-tracking';
import { IImage } from 'helpers/types/forms';
import ChatIcon from 'public/icons/rise-icons/Chat.svg';
import HomeNavigation from './footer-home-navigation';
import { FooterLegalCopyRight } from './footer-legal';
// import DispensaryLicense from './footer-license';
import FooterLogo from './footer-logo';
import { FooterTocMenu } from './footer-toc-navigation';
import FooterSocialIcons from './social-media-icons';
import {
  FooterChatIconContainer,
  FooterContainer,
  FooterLeftSideContainer,
  // FooterLicenseContainer,
  FooterMainContainer,
  FooterRightSideContainer,
  HomeNavigationContainer,
  SocialIconsContainer,
  TocNavigationContainer,
} from './styles';

interface IFooterprops {
  licenseData?: {
    licenceNumberRecreationalMenu?: string;
    licenceNumberMedicalMenu?: string;
    licenseNumberLounge?: string;
    breadcrumbTitle?: string;
  };
  legalCopyRight?: string;
  logo: {
    file: string;
    name: string;
    width: number;
    height: number;
  };
  gtiLogo: {
    desktopImage: IImage;
    mobileImage: IImage;
  };
  backgroundImageDesktop: {
    file: string;
    name: string;
    width: number;
    height: number;
  };
  backgroundImageMobile: {
    file: string;
    name: string;
    width: number;
    height: number;
  };
  homeNavigationMenu: Array<{
    navigationLabel: string;
    navigationUrl: string;
  }>;
  tocNavigationMenu: Array<{
    navigationLabel: string;
    navigationUrl: string;
  }>;
  socialMediaUrls: {
    facebookPage: string;
    instagramPage: string;
    twitterPage: string;
  };
  chatCta: string;
  host: string;
  shopUrl: string;
  customerCenterData: {
    ccHeading: string;
    ccDescription: string;
    ccLogo: {
      mobileImage: {
        sys: {
          id: string;
        };
        title: string;
        url: string;
        description: any;
        width: number;
        height: number;
      };
      desktopImage: {
        sys: {
          id: string;
        };
        title: string;
        url: string;
        description: any;
        width: number;
        height: number;
      };
    };
    ccOptionsCollection: {
      items: {
        title: string;
        description: string;
        url: string;
        icon: {
          sys: {
            id: string;
          };
          title: string;
          url: string;
          description: any;
          width: number;
          height: number;
        };
      }[];
    };
  };
}

const Footer = ({
  licenseData,
  legalCopyRight,
  backgroundImageDesktop,
  backgroundImageMobile,
  homeNavigationMenu,
  tocNavigationMenu,
  socialMediaUrls,
  chatCta,
  logo,
  customerCenterData,
  host,
  gtiLogo,
  shopUrl,
}: IFooterprops) => {
  const { openChatEvent } = useChatEventTracking();
  const openChatModal = () => {
    openChatEvent();
    const data = {
      method: 'chat',
      link_name: '',
      link_type: 'button',
      communication_trigger: 'footer',
    };
    communicationInitiated(data);
  };

  return (
    <FooterContainer>
      {licenseData && (
        <FooterLicense
          title={licenseData?.breadcrumbTitle || ''}
          licenceNumberRecreationalMenu={licenseData?.licenceNumberRecreationalMenu || ''}
          loungeLicenseNumber={licenseData?.licenseNumberLounge || ''}
          licenceNumberMedicalMenu={licenseData?.licenceNumberMedicalMenu || ''}
        />
      )}
      <FooterMainContainer>
        <FooterLeftSideContainer>
          <FooterLogo logo={logo} host="/" />
          <HomeNavigationContainer>
            <HomeNavigation
              homeNavigationMenu={homeNavigationMenu || []}
              backgroundImageDesktop={backgroundImageDesktop}
              backgroundImageMobile={backgroundImageMobile}
              host={host}
              shopUrl={shopUrl}
            />
          </HomeNavigationContainer>
        </FooterLeftSideContainer>
        <FooterRightSideContainer>
          <TocNavigationContainer>
            <FooterTocMenu
              tocNavigationMenu={tocNavigationMenu || []}
              host={host}
              customerCenterData={customerCenterData}
            />
          </TocNavigationContainer>
          <FooterChatIconContainer>
            <SocialIconsContainer>
              <FooterSocialIcons socialMediaUrls={socialMediaUrls} />
            </SocialIconsContainer>
            {!host?.includes('oh') && (
              <IconWithTextButton
                color="green"
                button-type="secondary"
                iconPosition="before"
                size="lg"
                className="w-fit"
                icon={<ChatIcon />}
                onClick={openChatModal}
              >
                {chatCta}
              </IconWithTextButton>
            )}
          </FooterChatIconContainer>
        </FooterRightSideContainer>
      </FooterMainContainer>
      <FooterLegalCopyRight gtiLogo={gtiLogo} legalCopyRight={legalCopyRight as string} />
    </FooterContainer>
  );
};

export default Footer;
