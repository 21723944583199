import React from 'react';
import { Typography } from 'components/atoms/typography';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import { HeaderCannabisInsiderDesktopList, HeaderCannabisInsiderDesktopWrapper } from './styles';

type TProps = {
  data: any;
  onPressLink?: (name: string) => void;
  host?: string;
};

const HeaderCannabisInsiderDesktop = ({ data, host, onPressLink }: TProps) => {
  return (
    <HeaderCannabisInsiderDesktopWrapper>
      <HeaderCannabisInsiderDesktopList>
        {data &&
          data?.data?.map((item, index) => (
            <li className="cursor-pointer" key={index}>
              <Typography
                onClick={() => onPressLink && onPressLink(item?.navigationLabel)}
                as="a"
                variant="h2"
                className={'opacity-40 hover:opacity-100'}
                target={getLinkTarget(ReplaceUrlWithHost(item?.navigationUrl, host as string))}
                href={ReplaceUrlWithHost(item?.navigationUrl, host as string)}
              >
                {item?.navigationLabel}
              </Typography>
            </li>
          ))}
      </HeaderCannabisInsiderDesktopList>
    </HeaderCannabisInsiderDesktopWrapper>
  );
};

export default HeaderCannabisInsiderDesktop;
