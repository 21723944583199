import { useEffect, useRef, useState } from 'react';
import { ResponsiveContainer, StackedCarousel } from 'react-stacked-center-carousel';
import Pagination from 'components/atoms/pagination';
import Arrow from 'components/atoms/pagination/arrow';
import { CarouselImage } from 'components/molecules/carousels/carousel-image';
import { PaginationContainer } from 'components/molecules/carousels/styles';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getItemPropertyValue } from 'helpers/utils/grid-image-carousal';
import { largeDesktop, mobile, tablet } from 'helpers/utils/screensizes';

type TProps = {
  images: Record<string, any>[];
  paginationColor: 'resin' | 'white' | 'leaf';
  containerHeight: string;
  mobilePaginationVariant: 'center-carousel' | 'pill-mobile';
  handleCurrentPage?: (page: number) => void;
  isArticleCarousel?: boolean;
};

export const CenteredCarousel = ({
  images,
  paginationColor,
  containerHeight,
  mobilePaginationVariant,
  handleCurrentPage,
  isArticleCarousel = false,
}: TProps) => {
  const [isLargeDesktop] = useMediaQuery(largeDesktop);
  const [isMobile] = useMediaQuery(mobile);
  const [isTablet] = useMediaQuery(tablet);

  const ref = useRef<StackedCarousel | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  //Reposition prev/next carousel buttons
  useEffect(() => {
    const setButtonAttributes = (button, buttonWidth) => {
      const carousel = document.getElementsByClassName('stacked-carousel-responsive-container');
      const currentImage = document.getElementsByClassName('current')[0].parentElement;

      if (currentImage) {
        button.style.top =
          carousel[0].clientHeight < parseInt(currentImage.style.height.split('px')[0])
            ? carousel[0].clientHeight / 2 - buttonWidth + 'px'
            : `${isArticleCarousel ? 'calc(25% + ' : ''}${currentImage.clientHeight / 2 - buttonWidth + 'px'}${
                isArticleCarousel ? `)` : ''
              }`;
        button.style.position = 'absolute';
        button.style.zIndex = '10';
        button.role = 'button';
      }
    };
    const handleResize = () => {
      if (!document || document.getElementsByClassName('current').length === 0) return;
      const currentImage = document.getElementsByClassName('current')[0].parentElement;
      const carouselContainer = document.getElementsByClassName('stacked-carousel-responsive-container')[0].clientWidth;

      if (currentImage) {
        const imageWidth =
          carouselContainer < parseInt(currentImage.style.width.split('px')[0])
            ? carouselContainer
            : currentImage.children[0].clientWidth;
        const pagiantionLeft = document.getElementsByClassName('paginationLeft');
        const pagiantionLeftParent = pagiantionLeft.length > 0 ? pagiantionLeft[0].parentElement : null;
        if (pagiantionLeftParent) {
          const buttonWidth = pagiantionLeftParent.clientWidth / 2;
          setButtonAttributes(pagiantionLeftParent, buttonWidth);
          pagiantionLeftParent.style.left =
            carouselContainer < parseInt(currentImage.style.width.split('px')[0])
              ? `${0 - buttonWidth}px`
              : `calc(50% - ${parseInt(currentImage.style.left.split(' ')[2].split('px')[0]) + buttonWidth}px)`;
        }
        const pagiantionRight = document.getElementsByClassName('paginationRight');
        const pagiantionRightParent = pagiantionRight.length > 0 ? pagiantionRight[0].parentElement : null;
        if (pagiantionRightParent) {
          const buttonWidth = pagiantionRightParent.clientWidth / 2;
          setButtonAttributes(pagiantionRightParent, buttonWidth);
          if (carouselContainer < parseInt(currentImage.style.width.split('px')[0])) {
            pagiantionRightParent.style.right = `${0 - buttonWidth}px`;
            pagiantionRightParent.style.left = '';
          } else {
            pagiantionRightParent.style.left = `calc(50% - ${
              parseInt(currentImage.style.left.split(' ')[2].split('px')[0]) + buttonWidth - imageWidth
            }px)`;
            pagiantionRightParent.style.right = '';
          }
        }
      }
    };

    const waitForDocument = () => {
      if (document.getElementsByClassName('current').length === 0) {
        window.setTimeout(waitForDocument, 100);
      } else {
        handleResize();
      }
    };
    waitForDocument();
    window.addEventListener('resize', handleResize);

    //cleanup after unmounting
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const data =
    Array.isArray(images) && images.length > 0
      ? images.map((image) => {
          return {
            cover: getItemPropertyValue(image, 'url', isTablet),
            title: getItemPropertyValue(image, 'title', isTablet),
            width: getItemPropertyValue(image, 'width', isTablet),
            height: getItemPropertyValue(image, 'height', isTablet),
          };
        })
      : [];

  if (images?.length < 3) return <></>;

  return (
    Array?.isArray(images) && (
      <>
        <div style={{ height: containerHeight }} className={`${isArticleCarousel && 'absolute top-[25%] w-full'}`}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(parentWidth, carouselRef) => {
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={CarouselImage}
                  slideWidth={isLargeDesktop ? 608 : 294}
                  carouselWidth={parentWidth}
                  data={data}
                  currentVisibleSlide={3}
                  maxVisibleSlide={3}
                  useGrabCursor
                  fadeDistance={
                    mobilePaginationVariant === 'pill-mobile' && !isTablet
                      ? 1
                      : isLargeDesktop
                      ? 0.8
                      : isTablet
                      ? 0.7
                      : isMobile
                      ? 0.8
                      : 0.5
                  }
                  onActiveSlideChange={(activeSlide) => {
                    setCurrentPage(activeSlide + 1);
                    if (handleCurrentPage) {
                      handleCurrentPage(activeSlide);
                    }
                  }}
                />
              );
            }}
          />
        </div>
        <PaginationContainer className={`${isArticleCarousel ? 'absolute top-[65%] w-full' : null}`}>
          {mobilePaginationVariant === 'pill-mobile' && !isTablet ? (
            <Pagination
              count={images?.length}
              variant="pill-mobile"
              isVisible={true}
              onPageChange={(x) => {
                //@ts-ignore
                if (currentPage < x.page) ref.current?.goNext();
                //@ts-ignore
                else ref.current?.goBack();
                setCurrentPage(x.page);
                if (handleCurrentPage) {
                  handleCurrentPage(x.page);
                }
              }}
              color={paginationColor}
            />
          ) : (
            <Pagination
              count={images?.length}
              variant="center-carousel"
              currentPage={currentPage}
              isVisible={true}
              color={paginationColor}
            />
          )}
        </PaginationContainer>
        {mobilePaginationVariant === 'pill-mobile' && !isTablet ? null : (
          <>
            <Arrow
              className={'paginationLeft'}
              variant="left"
              onClick={() => {
                //@ts-ignore
                ref.current?.goBack();
              }}
            />
            <Arrow
              className={'paginationRight'}
              variant="right"
              onClick={() => {
                //@ts-ignore
                ref.current?.goNext();
              }}
            />
          </>
        )}
      </>
    )
  );
};
