import React from 'react';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { THeaderState, THeaderVariantTypes } from 'helpers/types/organisms/global/header';
import HeaderIcon from './header-icon';
import HeaderImage from './header-image';
import HeaderLink from './header-link';
import { HeaderContainerWrapper } from './styles';

type TProps = {
  variant: THeaderVariantTypes;
  toggleModal: (data: any) => void;
  closeModal: () => void;
  modal: any;
  data: any;
  isPageScrolled: boolean;
};

const HeaderContainer = ({ variant, toggleModal, modal, closeModal, data, isPageScrolled }: TProps) => {
  const handleClick = () => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_type: 'icon',
    });
    toggleModal(THeaderState.SEARCHBAR);
  };

  const headerImageData = modal?.extendedMenu ? data?.onExpandLogo : isPageScrolled ? data?.onScrollLogo : data?.logo;
  return (
    <HeaderContainerWrapper>
      <HeaderLink
        variant={variant}
        data={data?.navigationMenuCollection?.items}
        modal={modal}
        toggleModal={toggleModal}
        handleClick={handleClick}
        closeModal={closeModal}
      />
      <HeaderImage {...headerImageData} />
      <HeaderIcon handleClick={handleClick} variant={variant} />
    </HeaderContainerWrapper>
  );
};

export default HeaderContainer;
