import Image from 'next/image';
import { cn } from 'helpers/utils/cn';
export interface ImageBgProps {
  src: string;
  alt: string;
  ariaLabel: string;
  className?: string;
  children?: any;
  bgGradient?: string;
}

export const ImageBg = ({
  src = '/',
  alt = '',
  ariaLabel = '',
  className = '',
  children = <></>,
  bgGradient = '',
}: ImageBgProps) => {
  const gradient = bgGradient !== '' ? bgGradient + ',' : '';
  const imageClasses = 'object-cover bg-no-repeat bg-grey-600 md:shrink-0 md:!w-[972px] md:!h-[620px]';

  return (
    <>
      <div className="relative h-[360px] w-full md:h-[588px]" data-testid="banner-backgroung-image">
        <Image
          loader={(options) => options.src}
          src={src}
          aria-label={ariaLabel}
          className={cn(imageClasses, className)}
          style={{ backgroundImage: `${gradient} url('${src}')` }}
          fill
          sizes="100vw"
          arial-title={alt}
          role="img"
          alt=""
        />
      </div>
      {children}
    </>
  );
};
