'use client';

import Header from 'components/organisms/global/header';
import useDispensary from 'helpers/hooks/use-dispensary';
import { getCurrentStateSnackbarCookieValue } from 'helpers/utils/cookies';
import { DispensaryData } from 'frontastic/renderer/types';

type TProps = {
  data: {
    headerData: any;
    dispensariesListData: any;
  };
  dispensaryData: DispensaryData;
};

const HeaderComponent = ({ data, dispensaryData }: TProps) => {
  const host = window.location.origin;

  useDispensary(
    data?.dispensariesListData?.dataSource,
    dispensaryData.disId,
    dispensaryData.stateId,
    dispensaryData.storeId,
  );

  return (
    <Header
      data={data.headerData.dataSource.data}
      dispensariesList={data.dispensariesListData.dataSource}
      geoState={dispensaryData.stateId}
      snackBarCookies={getCurrentStateSnackbarCookieValue('gti_sticky_bar', dispensaryData.stateId)}
      host={host}
    />
  );
};

export default HeaderComponent;
