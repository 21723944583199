import tw from 'tailwind-styled-components';

export const AgeGateAlternativeVerificationFormContainer = tw.div`
  flex
  flex-col
  gap-3
  lg:gap-2.5
  2xl:gap-6
  w-[350px]
  lg:w-full
  2xl:w-[632px]
`;

export const AgeGateAlternativeVerificationFormFieldsContainer = tw.div`
  flex
  flex-row
  gap-3
  justify-center
  items-start
`;

export const AgeGateAlternativeVerificationFormFieldContainer = tw.div`
  !w-[109px]
  lg:!w-[221px]
  2xl:!w-[202px]
  test-me-123
  [&_div:nth-child(1)]:!min-w-[109px]
  lg:[&_div:nth-child(1)]:!min-w-[221px]
  2xl:[&_div:nth-child(1)]:!min-w-[202px]
`;
