'use client';

import { useEffect } from 'react';
import { DISP_TAGS } from 'helpers/types/cookies';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TCurrentDispensary, setDispensaryList } from 'redux/reducers/dispensary-list';

type TProps = {
  dispensaryListData?: any;
  stateID?: string;
  disID?: string;
  storeID?: string;
  currentDispensary?: TCurrentDispensary;
  dispensaryList?: TCurrentDispensary[];
  host?: string;
};
const formatDispensaryNameSlug = (slug: string) => {
  const name = slug.split('/');
  return name?.[3] || '';
};
const useDispensary = (dispensaryListData = [], stateID = '', disID = '', storeID = ''): TProps => {
  const dispatch = useAppDispatch();
  const host = useAppSelector((state) => state?.hostReducer?.host);
  const dispensaryListInfo = useAppSelector((state) => state?.dispensaryList);

  useEffect(() => {
    if (dispensaryListData?.length === 0 || !stateID || !disID || !storeID) return;
    const index = dispensaryListData?.findIndex(
      (
        { state }, // @ts-ignore
      ) => state?.slug === `/dispensaries/${disID}/`,
    );
    const data = dispensaryListData?.[index];

    // @ts-ignore
    const dispensaryIndex = data?.dispensaries?.findIndex(
      ({ slug }) => slug.toLowerCase() === `/dispensaries/${disID}/${stateID}/`.toLocaleLowerCase(),
    );

    // @ts-ignore
    const dispensary = data?.dispensaries?.[dispensaryIndex];

    if (dispensary) {
      // @ts-ignore
      const state = data?.state;
      const isComingSoon = dispensary?.dispensaryTags?.includes(DISP_TAGS.COMING_SOON);
      const dispensaries = {
        ...dispensary,
        dispensaryName: formatDispensaryNameSlug(dispensary?.slug?.toLowerCase()),
        storeId: isComingSoon ? DISP_TAGS.COMING_SOON : storeID,
      };

      dispatch(
        setDispensaryList({
          currentDispensary: { state, dispensaries } || {},
          dispensaryList: dispensaryListData,
        }),
      );
    }
  }, [JSON.stringify(dispensaryListData), stateID, disID, storeID]);

  return {
    currentDispensary: dispensaryListInfo?.currentDispensary || {},
    dispensaryList: dispensaryListInfo?.dispensaryList || [],
    host: host || '',
  };
};

export default useDispensary;
