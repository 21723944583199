import React from 'react';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TContenfulImageProp } from 'helpers/types';
import { desktop } from 'helpers/utils/screensizes';
import { DispensariesData } from '../types/dispensaries';
import HeaderStateDesktop from './header-state-desktop';
import HeaderStateMobile from './header-state-mobile';

type HeaderStateProps = {
  data?: DispensariesData[];
  image: TContenfulImageProp;
  toggleModal: (name: string, data: any) => void;
  onPressLink?: (name: string) => void;
};

const HeaderState = ({ data, image, toggleModal }: HeaderStateProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_EXPANDED, {
      nav_position: 'header',
      link_primary: name,
      link_type: 'hyperlink',
    });
  };

  return (
    <>
      {isDesktop ? (
        <HeaderStateDesktop data={data} toggleModal={toggleModal} image={image} onPressLink={handleOnPressLink} />
      ) : (
        <HeaderStateMobile data={data} toggleModal={toggleModal} onPressLink={handleOnPressLink} />
      )}
    </>
  );
};

export default HeaderState;
