import tw from 'tailwind-styled-components';

export const LocalizationIndicatorContainer = tw.div<{ $isChatMinimized: boolean }>`
  fixed
  z-[9]
  bottom-[2%]
  ${(props) =>
    props.$isChatMinimized
      ? 'right-[94px]'
      : 'right-[2%] max640:left-[50%] max640:-translate-x-[50%] max640:min-w-[380px]'}
  inline-flex
  lg:h-16
  pl-[22px]
  lg:pl-8
  py-4
  lg:py-[22px]
  pr-[26px]
  lg:pr-[38px]
  justify-end
  items-center
  shadow-3
  rounded-[32px]
  border
  border-solid
  border-grey-480
  cursor-pointer
  bg-white
`;

export const IconContainer = tw.div<{ $isChatMinimized: boolean }>`
  fixed
  z-[9]
  bottom-[2%]
  ${(props) => (props.$isChatMinimized ? 'right-[94px]' : 'right-[2%]')}
  w-14
  lg:w-16
  h-14
  lg:h-16
  p-4
  lg:p-5
  border
  border-solid
  border-grey-480
  bg-white
  shadow-3
  rounded-[40px]
  flex
  items-center
  justify-center
`;
