import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import ProductCardVertical, { TProps } from 'components/molecules/cards/product-card/product-card-vertical';
import SimpleImageOrVideo from 'components/molecules/image-or-video/simple-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import {
  BannerProductCardContainer,
  BannerProductContainer,
  BannerProductImageContianer,
  BannerProductInformationContainer,
  BannerProductItemContainer,
  BannerProductTitleStyles,
  BannerProductWrapper,
  CommonButtonStyles,
  Spacer,
} from './styles';

export type BgColorProps = 'kief' | 'grey';
export type VariantProps = 'card' | 'image';

type BannerProductProps = {
  title: string;
  subTitle: string;
  bgColor: BgColorProps;
  image: string;
  description?: IRichTextData;
  variant: VariantProps;
  productProps?: TProps;
  handleSegmentEvent?: (type: string) => void;
  productImageProps?: {
    additionalImage?: string;
    overlayImage: IContentfulImage;
    ctaText: string;
    ctaLink: string;
  };
};

export const BannerProduct = ({
  title,
  subTitle,
  bgColor,
  description,
  image,
  productProps,
  variant,
  productImageProps,
  handleSegmentEvent,
}: BannerProductProps) => {
  const containerStyle = bgColor === 'kief' && variant === 'image' ? { backgroundImage: `url(${image})` } : undefined;
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <BannerProductWrapper ref={targetRef} $variant={variant}>
      <BannerProductContainer $variant={variant} style={containerStyle}>
        <BannerProductInformationContainer $variant={variant}>
          {subTitle && (
            <Typography variant="body-small" className={BannerProductTitleStyles} data-testid="Product-banner-title">
              {subTitle}
            </Typography>
          )}
          {title && (
            <Typography
              variant="h3"
              className={`${variant === 'image' ? '' : 'lg:pb-[60px] 2xl:pb-0'}`}
              data-testid="Product-banner-subTitle"
            >
              {title}
            </Typography>
          )}
          {variant != 'card' && (
            <ContentfulRichText
              data={description as IRichTextData}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant="body-large">{children}</Typography>,
              }}
            />
          )}
          {variant === 'image' && (
            <CommonButton
              as="a"
              className={CommonButtonStyles}
              color="green"
              button-type="primary"
              href={productImageProps?.ctaLink || ''}
              onClick={() => handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
            >
              {productImageProps?.ctaText}
            </CommonButton>
          )}
        </BannerProductInformationContainer>
        <div className="lg:flex">
          <Spacer $variant={variant} />
          {variant === 'card' && (
            <BannerProductCardContainer data-testid="Product-card">
              <ProductCardVertical
                {...productProps}
                image={productProps?.image || ''}
                name={productProps?.name || ''}
                brandName={productProps?.brandName || ''}
              />
            </BannerProductCardContainer>
          )}
          {variant === 'image' && (
            <BannerProductItemContainer>
              <SimpleImageOrVideo
                imageOrVideo={productImageProps?.overlayImage as IContentfulImage}
                imageProps={{
                  width: 280,
                  height: 280,
                }}
              />
            </BannerProductItemContainer>
          )}
          <BannerProductImageContianer
            $variant={variant}
            style={{
              backgroundImage: `url(${productImageProps?.additionalImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </BannerProductContainer>
    </BannerProductWrapper>
  );
};
