import { SegmentEvents } from '../constants/forms';
import { ISortSelectEvent, SegementTrackEvent } from './segment-helper';

export const sortSelect = (value: ISortSelectEvent) => {
  try {
    const data: ISortSelectEvent = {
      placement: value.placement || 1,
      card_list_name: value.card_list_name || '',
      card_list_title: value.card_list_title || '',
      card_list_type: value.card_list_type || '',
      sorted_by: value.sorted_by || '',
    };
    return SegementTrackEvent(SegmentEvents.CARD_LIST_SORTED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
