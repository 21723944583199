import tw from 'tailwind-styled-components';

export const BannerDealsContainer = tw.div`
    bg-kief 
    grid 
    md:grid-cols-3
`;

export const BannerDealsImage = tw.div`
    relative 
    h-[134px] 
    w-full 
    md:h-[452px]
`;

export const BannerDealsBody = tw.div`
    md:col-span-2 
    px-5 
    pb-6 
    pt-0 
    md:py-16 
    md:pl-0 
    md:pr-[60px] 
    z-[1]
`;

export const BannerDealsContentWrapper = tw.div`
    grid 
    grid-cols-4 
    bg-white 
    rounded-md  
    -mt-[5%] 
    md:mt-0 
    md:-ml-[23%] 
    px-5 
    py-8 
    md:p-0
`;

export const BannerDealsContent = tw.div`
    p-0 
    md:px-[60px] 
    md:py-[88px] 
    col-span-3
`;

export const BannerDealsImageWrapper = tw.div`
    flex 
    self-end 
    justify-self-end 
    mb-0 
    md:-mb-[28%]
`;
