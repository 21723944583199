import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Pagination from 'components/atoms/pagination';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import {
  ButtonContainer,
  ButtonWraper,
  DescriptionButtonContainer,
  HomeSliderContainer,
  OverlayContainer,
  PaginationContainer,
  SliderCardContainer,
  SliderCardDescriptionWrapper,
  SliderCardDetailsContainer,
  SliderCardTitleWrapper,
  SliderDescriptionText,
  SliderTitleText,
} from './styles';

type TProps = {
  title: string;
  description: IRichTextData;
  image: IContentfulImage;
  mobileImage: IContentfulImage;
  chooseCardTemplate?: string;
  index: number;
  handlePageChange(x: number): void;
  itemsCount: number;
  handleSegmentEvent?: () => void;
  cta: {
    title: string;
    longUrl: string;
    openInNewTab: boolean;
  };
  ref?: any;
  host?: string;
};

const HomeSliderCard = ({
  title,
  description,
  image,
  chooseCardTemplate,
  mobileImage,
  itemsCount,
  handlePageChange,
  index,
  handleSegmentEvent,
  ref,
  cta,
}: TProps) => {
  const newUrl = getPathMenuFiltersLink(cta?.longUrl);
  const isDark = chooseCardTemplate === 'Dark';

  return (
    <HomeSliderContainer ref={ref}>
      <ResponsiveImageOrVideo
        desktop={image}
        mobile={mobileImage}
        imageProps={{
          objectFit: 'cover',
          alt: image?.title || '',
        }}
      />
      <OverlayContainer $chooseDarkTemplate={isDark} />
      <SliderCardDetailsContainer>
        <SliderCardContainer>
          {title && (
            <SliderCardTitleWrapper>
              <SliderTitleText variant="h1" as="p" $isDark={isDark}>
                {title}
              </SliderTitleText>
            </SliderCardTitleWrapper>
          )}
          <DescriptionButtonContainer>
            {description && (
              <SliderCardDescriptionWrapper>
                <ContentfulRichText
                  data={description}
                  renderNodeOptions={{
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <SliderDescriptionText variant="body-large-bold" as="p" $isDark={isDark}>
                        {children}
                      </SliderDescriptionText>
                    ),
                  }}
                />
              </SliderCardDescriptionWrapper>
            )}
            <ButtonContainer>
              {cta.title && (
                <ButtonWraper>
                  <CommonButton
                    onClick={handleSegmentEvent}
                    color={isDark ? 'white' : 'green'}
                    button-type="primary"
                    as="a"
                    href={newUrl || '/'}
                    target={getLinkTarget(newUrl, cta?.openInNewTab)}
                  >
                    {cta?.title}
                  </CommonButton>
                </ButtonWraper>
              )}
              <PaginationContainer>
                <Pagination
                  isVisible={true}
                  variant="small"
                  color={isDark ? 'white' : 'resin'}
                  count={itemsCount}
                  slickCurrentIndex={index}
                  onNextPrevClick={handlePageChange}
                />
              </PaginationContainer>
            </ButtonContainer>
          </DescriptionButtonContainer>
        </SliderCardContainer>
      </SliderCardDetailsContainer>
    </HomeSliderContainer>
  );
};

export default HomeSliderCard;
