import Image from 'next/image';
interface TProps {
  data: Record<string, any>;
  dataIndex: any;
  isCenterSlide: boolean;
}
export const CarouselImage = ({ data, dataIndex, isCenterSlide }: TProps) => {
  const { cover, title, width, height } = data[dataIndex] || {};

  return (
    <div key={dataIndex} className={`${!isCenterSlide ? 'brightness-50' : 'current'}`}>
      <Image
        loader={(options) => options.src}
        alt={title}
        src={cover}
        width={width}
        height={height}
        objectFit="contain"
        className="rounded-md"
      />
    </div>
  );
};
