import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { THeaderState } from 'helpers/types/organisms/global/header';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { DispensariesData } from '../../types/dispensaries';
import {
  HeaderStateMobileButtonBox,
  HeaderStateMobileList,
  HeaderStateMobileListItem,
  HeaderStateMobileListLink,
  HeaderStateMobileWrapper,
} from './styles';

type TProps = {
  data?: DispensariesData[];
  onPressLink?: (name: string) => void;
  toggleModal: (name: string, data?: DispensariesData) => void;
};

const HeaderStateMobile = ({ toggleModal, data, onPressLink }: TProps) => {
  return (
    <HeaderStateMobileWrapper data-testid="header-state-mobile">
      <HeaderStateMobileButtonBox>
        <IconButton
          button-type="primary"
          className="!bg-kief !text-leaf"
          color="green"
          onClick={() => toggleModal(THeaderState.NAVBAR)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h2" className="!text-kief">
          Dispensaries
        </Typography>
      </HeaderStateMobileButtonBox>
      <HeaderStateMobileList>
        {data &&
          data?.map((item, index) => (
            <HeaderStateMobileListItem key={index}>
              <HeaderStateMobileListLink
                onClick={() => {
                  onPressLink && onPressLink(item?.state?.state?.stateName),
                    toggleModal(THeaderState.CHANGEDDISPENSARY, item);
                }}
              >
                <Typography variant="h5" className=" text-white">
                  {item?.state?.state?.stateName}
                </Typography>
                <ChevronRightIcon />
              </HeaderStateMobileListLink>
            </HeaderStateMobileListItem>
          ))}
      </HeaderStateMobileList>
    </HeaderStateMobileWrapper>
  );
};

export default HeaderStateMobile;
