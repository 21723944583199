import React from 'react';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { TContenfulImageProp } from 'helpers/types';
import { SmallImageContainer } from './styles';
type TProps = {
  image: TContenfulImageProp;
  alignment?: boolean;
  isTablet?: boolean;
  isMediumDesktop?: boolean;
};
const SmallImage = ({ image, alignment, isTablet, isMediumDesktop }: TProps) => {
  return (
    <SmallImageContainer $alignment={alignment}>
      <ResponsiveImageOrVideo
        desktop={image}
        mobile={image}
        imageProps={{
          imageHeight: isMediumDesktop ? 368 : isTablet ? 245 : 393.34,
          imageWidth: isMediumDesktop ? 736 : isTablet ? 435 : 688.34,
          className: 'px-5 pb-8 md:rounded-md',
          alt: image?.title || '',
        }}
      />
    </SmallImageContainer>
  );
};

export default SmallImage;
