export const generateOhioHost = (host: string) => {
  const parts = host?.split('risecannabis');
  const hostBasedOnSubdomian = parts[0]?.includes('.')
    ? parts[0]?.replace('.', '-oh.') + 'risecannabis' + parts[1]?.replace('/', '')
    : parts[0] + 'oh.risecannabis' + parts[1]?.replace('/', '');

  const newHost = parts?.length > 1 ? hostBasedOnSubdomian : host;

  return newHost;
};

export const generateOhioLink = (host: string, slug: string) => {
  const newHost = generateOhioHost(host);
  return newHost + slug?.replace('{{url}}', '')?.replace('{{url-oh}}', '');
};
