import tw from 'tailwind-styled-components';

export const BannerRewardsContainer = tw.div`
    flex
    flex-col
    md:flex-row
`;

export const BIRewardsImage = tw.div`
    flex
    w-full
    md:max-w-[50%]
    overflow-hidden
`;

export const BIRewardsDetailContainer = tw.div`
    w-full
    md:max-w-[50%]
    px-[40px]
    pb-[60px]
    pt-[44px]
    md:p-[37px]
    xl:p-[80px]
    flex
    flex-col
    md:flex-row
    items-center
    justify-center
`;

export const BIRewardsOverlayImg = tw.div`
    mb-[44px]
    mt-[-135px]
    md:mt-0
    md:mb-0
    md:mr-[37px]
    md:ml-[-25%]
    xl:mr-[80px]
    xl:ml-[-50%]
    w-full
    max-w-[190px]
    md:max-w-[200px]
    xl:max-w-[278px]
    overflow-hidden
`;

export const BIRewardsBody = tw.div`
    w-full
    md:max-w-[480px]
    xl:max-w-[525px]
    flex
    flex-col
    gap-6
    md:gap-5
    xl:gap-6
`;
