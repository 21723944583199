import { useDispatch, useSelector } from 'react-redux';
import { SegementCartTriggerSource } from 'helpers/analytics/cart-track';
import { COMMON_COOKIES } from 'helpers/types/cookies';
import { getCookie } from 'helpers/utils/cookies';
import { setCartTrigger } from 'redux/reducers/product-cart';
import { SnackbarCtaType, closeSnackbar, openSnackbar } from 'redux/reducers/snackbar-notification';
import { RootState } from 'redux/store';
import { SnackBarComponent } from '.';

const CartSnackbar = (setIsCartDrawerOpen) => {
  const dispatch = useDispatch();
  const snackbarNotification = useSelector((state: RootState) => state.snackbarNotification);
  const host = useSelector((state: RootState) => state.hostReducer.host);

  const openSnackbarHandler = (content) => {
    dispatch(
      openSnackbar({
        content: content,
        ctaActionType: SnackbarCtaType.CART_DRAWER,
      }),
    );
    // @ts-ignore
    document.getElementById('rise-header').classList.add('visible-snack-bar-header');
    // @ts-ignore
    document.getElementById('mainContent').classList.add('visible-snack-bar-main-content');

    setTimeout(() => {
      closeSnackbarHandler();
    }, 5000);
  };

  const closeSnackbarHandler = () => {
    dispatch(closeSnackbar());
    const cookieValue = getCookie(COMMON_COOKIES.STICKY_BAR);

    if (Boolean(cookieValue)) {
      // @ts-ignore
      document.getElementById('rise-header').classList.remove('visible-snack-bar-header');
      // @ts-ignore
      document.getElementById('mainContent').classList.remove('visible-snack-bar-main-content');
    }
  };

  const snackBarCta = () => {
    if (!snackbarNotification?.ctaActionType) return null;
    if (snackbarNotification?.ctaActionType === 'CART_DRAWER') {
      setIsCartDrawerOpen(true);
      dispatch(setCartTrigger(SegementCartTriggerSource.SNACKBAR));
    }
  };

  return {
    CartSnackbarComponent: () => (
      <SnackBarComponent
        backgroundColor="leafGreen"
        title={snackbarNotification?.content as string}
        cta={{
          title: 'View Cart',
        }}
        showSnackBar={snackbarNotification?.isOpen}
        handleCloseAction={closeSnackbarHandler}
        onClick={snackBarCta}
        zindexPlacement="z-21"
        host={host}
      />
    ),
    openSnackbarHandler: openSnackbarHandler,
  };
};

export default CartSnackbar;
