import React from 'react';
import Image from 'next/image';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ReplaceUrlWithHost, getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { TImage } from '..';
import {
  BannerDealsBody,
  BannerDealsContainer,
  BannerDealsContent,
  BannerDealsContentWrapper,
  BannerDealsImage,
  BannerDealsImageWrapper,
} from './styles';

type TBannerDealsProps = {
  title: string;
  subTitle?: string;
  image: TImage;
  overlayImage?: TImage;
  handleSegmentEvent?: (type?: string) => void;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
};

const BannerDeals = ({
  title,
  subTitle,
  image,
  ctaCollection,
  overlayImage,
  handleSegmentEvent,
  host,
}: TBannerDealsProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const paersedCtaUrl = ctaCollection?.longUrl ? ctaCollection?.longUrl : ctaCollection?.url;
  const ctaUrl = ReplaceUrlWithHost(paersedCtaUrl || '{{url}}/', host as string);
  const newUrl = getPathMenuFiltersLink(ctaUrl);
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <BannerDealsContainer ref={targetRef} data-automationid="c10_banner_a_00">
      <BannerDealsImage>
        {(image?.mobileImage?.url || image?.desktopImage?.url) && (
          <Image
            src={!isTablet && image?.mobileImage ? image?.mobileImage?.url || '' : image?.desktopImage?.url || ''}
            loader={(options) => options.src}
            alt={!isTablet && image?.mobileImage ? image?.mobileImage?.title || '' : image?.desktopImage?.title || ''}
            layout="fill"
            objectFit="cover"
            className="h-full w-full"
          />
        )}
      </BannerDealsImage>
      <BannerDealsBody>
        <BannerDealsContentWrapper>
          <BannerDealsContent>
            <Typography variant="body-small" className="pb-2  !text-resin md:pb-4">
              {subTitle}
            </Typography>
            <Typography variant="h3" className="pb-4 md:pb-6">
              {title}
            </Typography>
            <IconWithTextButton
              button-type="tertiary"
              color="green"
              size="lg"
              className="!p-0 lg:!p-0"
              icon={<ChevronRightIcon />}
              iconPosition="after"
              as="a"
              target={getLinkTarget(newUrl)}
              href={newUrl}
              onClick={() => handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
            >
              {ctaCollection?.title}
            </IconWithTextButton>
          </BannerDealsContent>
          <BannerDealsImageWrapper>
            {(overlayImage?.mobileImage?.url || overlayImage?.desktopImage?.url) && (
              <Image
                src={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.url || ''
                    : overlayImage?.desktopImage?.url || ''
                }
                loader={(options) => options?.src}
                alt={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.title || ''
                    : overlayImage?.desktopImage?.title || ''
                }
                width={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.width
                    : overlayImage?.desktopImage?.width
                }
                height={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.height
                    : overlayImage?.desktopImage?.height
                }
              />
            )}
          </BannerDealsImageWrapper>
        </BannerDealsContentWrapper>
      </BannerDealsBody>
    </BannerDealsContainer>
  );
};

export default BannerDeals;
