import ReactMarkdown from 'react-markdown';
import { TransformOptions } from 'react-markdown/lib/react-markdown';
import { ReplaceUrlWithHost } from 'helpers/utils/common';

interface IProps {
  text?: string;
  host?: string;
  transformOptions?: TransformOptions;
  className?: string;
}

export const MarkDownText = ({ text, host, transformOptions, className }: IProps) => {
  if (host) {
    text = ReplaceUrlWithHost(text as string, host, true);
  }

  return (
    // @ts-ignore
    <ReactMarkdown {...transformOptions} className={className}>
      {text}
    </ReactMarkdown>
  );
};
