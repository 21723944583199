'use client';

import React, { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import { useDispatch } from 'react-redux';
import { COMMON_COOKIES } from 'helpers/types/cookies';
import { THeaderVariantTypes } from 'helpers/types/organisms/global/header';
import { getCurrentStateSnackbarCookieValue } from 'helpers/utils/cookies';
import { useAppSelector } from 'redux/hooks';
import { clearSearchState } from 'redux/reducers/search';
import HeaderCannabisInsider from './header-cannabis-insider';
import HeaderContainer from './header-container';
import HeaderNavbarMobile from './header-container/header-navbar-mobile';
import HeaderDispensaries from './header-dispensaries';
import { Asset } from './header-dispensaries/types/common';
import { DispensariesData } from './header-dispensaries/types/dispensaries';
import { MenuExtendedType } from './header-dispensaries/types/menu-extended';
import { HeaderDataType } from './header-dispensaries/types/navigation';
import HeaderSearch from './header-search';
import HeaderShop from './header-shop';
import HeaderShopSectionMobile from './header-shop/header-shop-section-mobile';
import { HeaderBox, HeaderWrapper } from './styles';

export type HeaderProps = {
  data: HeaderDataType;
  dispensariesList: DispensariesData[];
  snackBarCookies: boolean;
  geoState: string;
  host: string;
};

const Header = ({ data, dispensariesList, snackBarCookies, geoState, host }: HeaderProps) => {
  const headerLinkData = data?.sectionHeaderCollection?.items[0];

  const initialState: MenuExtendedType = {
    extendedMenu: false,
    navbar: { isOpen: false },
    shop: { isOpen: false, data: undefined },
    categories: { isOpen: false, data: undefined },
    brands: { isOpen: false, data: undefined },
    usages: { isOpen: false, data: undefined },
    dispensaries: { isOpen: false },
    changeDispensary: { isOpen: false, data: undefined },
    cannabisInsider: { isOpen: false, data: undefined },
    searchBar: { isOpen: false, data: undefined },
  };

  const [modal, setModal] = useState(initialState);
  const searchBarState = useAppSelector((state) => state?.search?.data?.searchBar);

  const dispatch = useDispatch();
  const [isPageScrolled, setIsPageScrolled] = useState<boolean>(true);

  const [variant, setVariant] = useState<THeaderVariantTypes>('dark');
  const [addSnakbarCss, setAddSnackbarCss] = useState<boolean>(snackBarCookies);

  const toggleModal = (modalId: string, modalData?: MenuExtendedType) => {
    setModal((prevModal) => {
      const modalItem = {
        ...Object.keys(prevModal).reduce((modalItem, key) => {
          if (key === modalId) {
            modalItem[key] = {
              isOpen: !prevModal[key].isOpen,
              data: modalData || prevModal[key].data,
            };
          } else {
            modalItem[key] = { isOpen: false, data: prevModal[key].data };
          }
          return modalItem;
        }, {} as MenuExtendedType),
      };

      modalItem.extendedMenu = modalItem[modalId].isOpen || false;

      setVariant(modalItem?.extendedMenu ? 'dark' : 'light');

      if (searchBarState?.isOpen) dispatch(clearSearchState());
      return modalItem;
    });
  };

  const closeModal = () => {
    setModal(initialState);
    const chooseVariantByScroll = isPageScrolled ? 'dark' : 'light';
    setVariant(chooseVariantByScroll);
  };

  const dispensariesImage = headerLinkData?.navigationMenuCollection.items.filter(
    (item) => item?.navigationLabel?.toLowerCase() === 'dispensaries',
  )?.[0]?.navigationBanner;

  useEffect(() => {
    const scrollChangeColor = () => {
      const state = window?.scrollY >= 90;
      setIsPageScrolled(state);
    };

    window?.addEventListener('scroll', scrollChangeColor);
    // router.events.on('routeChangeComplete', () => {
    //   closeModal();
    // });
    return () => {
      // router.events.off('routeChangeComplete', () => {
      //   closeModal();
      // });
      window?.removeEventListener('scroll', scrollChangeColor);
    };
  }, []);

  useEffect(() => {
    const chooseVariantByScroll = isPageScrolled ? 'dark' : 'light';
    setVariant(modal.extendedMenu ? 'dark' : chooseVariantByScroll);
    const cookies = getCookie(COMMON_COOKIES.STICKY_BAR) || '';
    const state = getCurrentStateSnackbarCookieValue(cookies, geoState);

    console.log('getCurrentStateSnackbarCookieValue', state);

    setAddSnackbarCss(state);
  }, [modal, isPageScrolled, geoState]);

  return (
    <HeaderWrapper
      id="rise-header"
      className={addSnakbarCss ? undefined : 'visible-snack-bar-header'}
      data-testid="header-wrapper"
      $variant={variant}
    >
      <HeaderSearch toggleModal={toggleModal} modal={modal} />
      <HeaderBox>
        <HeaderContainer
          data={headerLinkData}
          variant={variant}
          modal={modal}
          toggleModal={toggleModal}
          closeModal={closeModal}
          isPageScrolled={isPageScrolled}
        />
        {modal?.navbar.isOpen && (
          <HeaderNavbarMobile
            data={headerLinkData?.navigationMenuCollection?.items}
            host={host}
            toggleModal={toggleModal}
          />
        )}
        {modal?.shop.isOpen && <HeaderShop data={data} modal={modal} toggleModal={toggleModal} />}
        {modal?.cannabisInsider.isOpen && <HeaderCannabisInsider modal={modal} toggleModal={toggleModal} host={host} />}
        {(modal?.categories?.isOpen || modal?.brands?.isOpen || modal?.usages?.isOpen) && (
          <HeaderShopSectionMobile modal={modal} data={data} toggleModal={toggleModal} />
        )}
        {(modal?.dispensaries.isOpen || modal?.changeDispensary?.isOpen) && (
          <HeaderDispensaries
            data={dispensariesList}
            modal={modal}
            toggleModal={toggleModal}
            image={dispensariesImage as Asset}
            host={host}
          />
        )}
      </HeaderBox>
    </HeaderWrapper>
  );
};

export default Header;
