import React from 'react';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TContenfulImageProp } from 'helpers/types';
import { desktop } from 'helpers/utils/screensizes';
import { NavigationOrDispensariesData } from '../types/common';
import { DispensariesData } from '../types/dispensaries';
import HeaderChangeDispensaryDesktop from './header-change-dispensary-desktop';
import HeaderChangeDispensaryMobile from './header-change-dispensary-mobile';

type TProps = {
  host: string;
  image: TContenfulImageProp;
  data: NavigationOrDispensariesData;
  toggleModal: (data: any) => void;
};

const HeaderChangeDispensary = ({ data, image, host, toggleModal }: TProps) => {
  const [isDesktop] = useMediaQuery(desktop);

  const handleOnPressLink = (stateName: string, name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_final: name,
      link_secondary: stateName,
      link_primary: 'dispensaries',
      link_type: 'hyperlink',
    });
  };

  return (
    <>
      {isDesktop ? (
        <HeaderChangeDispensaryDesktop
          toggleModal={toggleModal}
          data={data as DispensariesData}
          image={image}
          onPressLink={handleOnPressLink}
          host={host}
        />
      ) : (
        <HeaderChangeDispensaryMobile
          toggleModal={toggleModal}
          data={data as DispensariesData}
          onPressLink={handleOnPressLink}
          host={host}
        />
      )}
    </>
  );
};

export default HeaderChangeDispensary;
