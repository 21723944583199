import tw from 'tailwind-styled-components';

interface SpecialOfferBannerProps {
  $richTextCustom?: boolean;
}

export const BannerSpecialOfferContainer = tw.div<SpecialOfferBannerProps>`
    bg-white 
    ${(props) => (props.$richTextCustom ? '' : 'p-5 md:p-[40px] xl:px-[60px] xl:py-[64px]')}
`;

export const BannerSpecialOfferBody = tw.div`
    flex
    flex-col
    md:flex-row
    bg-grey-100
    justify-between
`;

export const BannerSpecialOfferContentWrapper = tw.div`
    px-4
    pt-[44px]
    pb-[32px]
    md:p-[40px]
    flex
    flex-col
    gap-6
    md:gap-5
    xl:gap-8
    justify-center
    items-center
    md:items-start
`;

export const BannerSpecialOfferContent = tw.div`
    w-full
    md:max-w-[395px]
    xl:max-w-[524px]
    flex
    flex-col
    gap-4
    md:gap-5
    xl:gap-6
`;

export const BannerSpecialOfferImageWrapper = tw.div`
    flex
    w-full
    max-w-[727px]
    md:max-w-[725px]
    xl:max-w-[1013px]
`;
