import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { THeaderShopUrlEnums, THeaderState } from 'helpers/types/organisms/global/header';
import { getLinkTarget } from 'helpers/utils/common';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { CommonNavigation } from '../../../header-dispensaries/types/common';
import {
  HeaderShopListMobileIconBox,
  HeaderShopListMobileList,
  HeaderShopListMobileListItem,
  HeaderShopListMobileListLink,
  HeaderShopListMobileWrapper,
} from './styles';

type TProps = {
  toggleModal: (name: string) => void;
  modalShopData?: CommonNavigation[];
};

const HeaderShopListMobile = ({ toggleModal, modalShopData }: TProps) => {
  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_EXPANDED, {
      nav_position: 'header',
      link_primary: name,
      link_type: 'hyperlink',
    });
  };

  const handleNavItem = (name: string) => {
    handleOnPressLink(name);
    switch (name) {
      case THeaderShopUrlEnums.CATEGORIES:
        return toggleModal(THeaderState.CATEGORIES);
      case THeaderShopUrlEnums.BRANDS:
        return toggleModal(THeaderState.BRANDS);
      case THeaderShopUrlEnums.USAGES:
        return toggleModal(THeaderState.USAGES);
    }
  };

  return (
    <HeaderShopListMobileWrapper data-testid="header-shop-mobile">
      <HeaderShopListMobileIconBox>
        <IconButton
          button-type="primary"
          className="!bg-kief !text-leaf"
          color="green"
          onClick={() => toggleModal(THeaderState.NAVBAR)}
          data-testid="headerShopBackButton"
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h2" className="!text-kief">
          Shop
        </Typography>
      </HeaderShopListMobileIconBox>
      <HeaderShopListMobileList data-testid="headerShopList">
        {modalShopData?.map((item, index) => (
          <HeaderShopListMobileListItem key={index}>
            {item?.isDrawer ? (
              <HeaderShopListMobileListLink
                href="#"
                onClick={() => handleNavItem(item?.navigationUrl)}
                data-testid={`navLink${index}`}
              >
                <Typography variant="h5" className="text-white">
                  {item?.navigationLabel}
                </Typography>
                <ChevronRightIcon className="text-white" />
              </HeaderShopListMobileListLink>
            ) : (
              <HeaderShopListMobileListLink
                href={item?.navigationUrl}
                target={getLinkTarget(item?.navigationUrl)}
                onClick={() => handleOnPressLink(item.navigationUrl)}
                data-testid={`navLink${index}`}
              >
                <Typography variant="h5" className="text-white">
                  {item?.navigationLabel}
                </Typography>
              </HeaderShopListMobileListLink>
            )}
          </HeaderShopListMobileListItem>
        ))}
      </HeaderShopListMobileList>
    </HeaderShopListMobileWrapper>
  );
};

export default HeaderShopListMobile;
