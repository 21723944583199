import { SegmentEvents } from '../constants/forms';
import { IAgeGateTrackEventProps, SegementTrackEvent } from './segment-helper';

export const ageGateConsentedTrack = async ({
  terms_of_service,
  privacy_policy,
  notice_of_privacy_practices,
  response,
}: IAgeGateTrackEventProps) => {
  try {
    const eventData: IAgeGateTrackEventProps = {
      terms_of_service,
      privacy_policy,
      notice_of_privacy_practices,
      response,
    };
    return await SegementTrackEvent(SegmentEvents.AGE_CONSENTED, eventData, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
