'use client';

import { useMemo } from 'react';
import { TAdditionalContext } from 'helpers/types';
import { DISP_TAGS, MUNDY_STORE_ID } from 'helpers/types/cookies';
import { IDispensariesList } from 'helpers/types/forms';
import { store } from 'redux/store';
import { generateOhioHost } from './navigation/generate-oh-link';

const facetLabels = {
  category: 'root_types',
  brands: 'brand',
  feelings: 'feelings',
  specials: 'applicable_special_ids',
  'sub categories': 'root_types',
  activities: 'activities',
  lineage: 'category',
  'custom row': 'root_types',
};

export const ReplaceUrlWithHost = (url: string, host: string, isContent?: boolean) => {
  let newUrl = url;
  const isOH = store?.getState()?.hostReducer?.isCurrentHostOH;
  const { state } = store?.getState()?.dispensaryList?.currentDispensary;
  if (!isOH && state?.state?.stateName?.toLowerCase() === 'ohio' && newUrl?.includes('rise-rewards') && !isContent) {
    newUrl = newUrl?.replace('{{url}}', '{{url-oh}}');
  }
  if (!isOH && newUrl?.includes('ohio') && !isContent) {
    newUrl = newUrl?.replace('{{url}}', '{{url-oh}}');
  }
  if (!host) return newUrl?.replace(/{{url}}/g, '').replace(/{{url-oh}}/g, '');
  if (host?.includes('oh')) return newUrl?.replace(/{{url}}/g, host).replace(/{{url-oh}}/g, host);
  const ohHost = generateOhioHost(host);

  return newUrl?.replace(/{{url}}/g, host).replace(/{{url-oh}}/g, ohHost);
};

export const generateHashId = (str: string) => {
  if (str === null) {
    return '';
  }
  if (typeof str !== 'string') {
    str = String(str);
  }
  return `toc_${str.replace(/ /g, '_').toLowerCase()}`;
};

export const addTrailingSlash = (url: string) => {
  if (!url?.match(/\/$/)) {
    url += '/';
  }
  return url;
};

export const autoIdFromAdditionalContext = (additionalContext: TAdditionalContext, pageType?: string) => {
  if (!additionalContext || !additionalContext.componentType) return null;
  if (pageType === 'productListingPage' || pageType === 'productDetailPage') return null;

  const automationId = `${additionalContext?.skipPageType ? '' : pageType + '_'}${
    additionalContext?.componentType || ''
  }${additionalContext?.componentIndex?.toString() || ''}_${additionalContext?.tagRole || ''}${
    additionalContext?.roleIndex?.toString() || ''
  }`;

  return automationId;
};

export const MemoizedAutoIdFromAdditionalContext = (additionalContext: TAdditionalContext, pageType?: string) => {
  const memoizedResult = useMemo(
    () => autoIdFromAdditionalContext(additionalContext, pageType),
    [additionalContext, pageType],
  );

  return memoizedResult;
};

export const getMenuLink = ({
  menuType,
  fulfilmentType,
  dispensaries,
}: { menuType?: string; fulfilmentType?: string; dispensaries?: IDispensariesList } = {}) => {
  const host = store.getState().hostReducer.host;

  // TODO: it generates undefined menu link for the first time, because the currentDispensary is not available in the store
  // /dispensaries/california/pasadena/5405/recreational-pickup-menu/
  const currentDispensary = {
    dispensaries: {
      dispensaryInformation: {},
      heroImage: {
        url: '',
        title: '',
        height: 0,
        width: 0,
      },
      dispensaryName: 'pasadena',
      menusCollection: {
        items: [
          {
            menuType: 'recreational',
            fulfilmentType: 'delivery',
            menuSourceId: '5406',
            menuSource: 'internal',
            linkedFrom: {
              pageMenuCollection: {
                items: [{ slug: '/dispensaries/california/pasadena/5405/recreational-pickup-menu/' }],
              },
            },
          },
        ],
      },
      showChatBox: true,
      slug: '/dispensaries/california/',
      storeId: '5406',
      dispensaryTags: [],
    },
  };
  //const currentDispensary = store?.getState()?.dispensaryList?.currentDispensary;
  const isOH = store.getState().hostReducer.isCurrentHostOH;
  const { storeId, dispensaryTags, menusCollection, slug } = dispensaries || currentDispensary.dispensaries;

  //handle local setuo
  let newStoreId = storeId;
  let mundyStoreId = MUNDY_STORE_ID.PROD;

  if (process.env.NEXT_PUBLIC_CUST_ENV !== 'production') {
    mundyStoreId = MUNDY_STORE_ID.DEV;
    newStoreId = storeId === MUNDY_STORE_ID.PROD ? MUNDY_STORE_ID.DEV : storeId;
  }

  // @ts-ignore
  const isComingSoon = dispensaryTags?.includes(DISP_TAGS.COMING_SOON);
  if (isComingSoon) {
    return host + slug;
  }

  // @ts-ignore
  const isLounge = dispensaryTags?.includes(DISP_TAGS.LOUNGE);
  if (isLounge) {
    // returning Mundy rec menu URL
    return `${host}/dispensaries/illinois/mundelein/${mundyStoreId}/recreational-menu/`;
  }

  const menuDetails = menusCollection?.items;

  let filteredItems;

  if (menuType || fulfilmentType) {
    if (menuType && fulfilmentType) {
      filteredItems =
        menuDetails &&
        menuDetails?.find(
          (item) =>
            item?.menuType?.toLowerCase() === menuType?.toLowerCase() &&
            item?.fulfilmentType?.toLowerCase() === fulfilmentType?.toLowerCase(),
        );
    } else if (menuType) {
      filteredItems =
        menuDetails && menuDetails?.find((item) => item?.menuType?.toLowerCase() === menuType?.toLowerCase());
    } else if (fulfilmentType) {
      filteredItems =
        menuDetails &&
        menuDetails?.find((item) => item?.fulfilmentType?.toLowerCase() === fulfilmentType?.toLowerCase());
    }
  } else if (storeId === 'null' || storeId === 'undefined') {
    filteredItems = menuDetails && menuDetails?.find((item) => item.isDefault === 'yes');
  } else {
    filteredItems = menuDetails && menuDetails?.find((item) => item.menuSourceId === newStoreId);
  }

  const isExternal = filteredItems?.menuSource === 'external';

  if (isExternal) {
    return filteredItems?.menuSourceUrl;
  }

  const menuUrl = filteredItems?.linkedFrom?.pageMenuCollection?.items?.[0]?.slug;

  if (!isOH && menuUrl?.includes('ohio')) {
    return generateOhioHost(host);
  }

  return host + menuUrl;
};

export const getMenuFiltersLink = (algoliaId?: string, algoliaValue?: string) => {
  const currentDispensary = store?.getState()?.dispensaryList?.currentDispensary;
  const menuLink = getMenuLink();

  const storeId = currentDispensary.dispensaries?.storeId;
  let urlObj = menuLink;
  if (algoliaId && algoliaValue && menuLink) {
    const facetLabel = facetLabels[algoliaId?.toLowerCase()] || '';
    urlObj =
      urlObj +
      '?' +
      `refinementList[${facetLabel}][]=${
        algoliaId.toLowerCase() === 'custom row'
          ? `${encodeURIComponent(algoliaValue)}-${storeId}`
          : encodeURIComponent(algoliaValue)
      }`;
    return urlObj?.toString() || '/';
  }

  return urlObj?.toString() || '/';
};

export const getPathMenuFiltersLink = (url: string, algoliaId?: string, algoliaValue?: string) => {
  const host = store.getState().hostReducer.host;
  const newUrl = ReplaceUrlWithHost(url, host);
  const currentDispensary = store?.getState()?.dispensaryList?.currentDispensary;
  const urlParams = new URLSearchParams(newUrl?.split('?')?.[1]);
  const fulfilmentType = urlParams?.get('fulfilment-type');
  const menuType = urlParams?.get('menu-type');
  // @ts-ignore
  const menuLink = getMenuLink({ menuType, fulfilmentType });
  const storeId = currentDispensary.dispensaries?.storeId;

  if (url?.includes('path=menu') && !url.includes(menuLink)) {
    const filter = newUrl?.split('?');

    if (algoliaId && algoliaValue) {
      const href = window?.location?.href?.toString();
      const paramsString = href?.split('?')?.[1];
      const urlParams = new URLSearchParams(paramsString);
      const paramsArray = Array?.from(urlParams?.entries());
      const kindParams = paramsArray?.filter(([key]) => key !== 'refinementList[root_types][]');
      const facetLabel = facetLabels[algoliaId.toLowerCase()] || '';

      const allParams = [
        ...kindParams,
        [
          `refinementList[${facetLabel}][]`,
          algoliaId.toLowerCase() === 'custom row' ? `${algoliaValue}-${storeId}` : encodeURIComponent(algoliaValue),
        ],
      ];

      const newParamsString = allParams?.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)?.join('&');

      return `${menuLink}?${newParamsString}`;
    } else {
      return `${menuLink}?${filter[1]}`;
    }
  }

  return newUrl;
};

export const getLinkTarget = (url: string, openInNewTab = false) => {
  const host = store.getState().hostReducer.host;
  const isOH = store.getState().hostReducer.isCurrentHostOH;

  return !url?.includes(host) || openInNewTab
    ? '_blank'
    : !isOH && url?.includes('ohio') && url?.includes('oh')
    ? '_blank'
    : '_self';
};
