import React from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import QuantitySelector from 'components/atoms/buttons/quantity-selector';
import { WeightSelectorButton } from 'components/atoms/buttons/weight-selector';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { SegementAddToCartTriggerSource } from 'helpers/analytics/cart-product-added';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import { useCartState } from 'helpers/utils/cart-funationaltiy';
import { mobile } from 'helpers/utils/screensizes';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ProductCardTypography from '../../product-card-typography';
import {
  CardContainers,
  CardVerticalBox,
  CardVerticalImage,
  CardVerticalImageBody,
  CardVerticalImageBox,
  CardVerticalInfoBox,
  CardVerticalWeightBox,
} from './styles';

type TProps = {
  containersStyle?: string;
  image: string;
  name: string;
  brandName: string;
  price: string;
  strainsLabel?: string;
  onClose: () => void;
  weights?: TProductSize[];
  productId?: number;
  defaultWeightSelect: string;
  handleSelectedWeight?: (item: any) => void;
  productSizes?: Array<TProductSize>;
  isShowWeightSelector?: boolean;
  maxCartLimit?: number;
  triggeredFrom?: string;
  thc?: string;
  offer: string;
  kind?: string;
  order?: number;
  position?: number;
  discountPrice?: string;
};
const ProductCardConfirmVertical = ({
  containersStyle,
  image,
  productId,
  order,
  name,
  kind,
  brandName,
  price,
  position,
  thc,
  defaultWeightSelect,
  onClose,
  strainsLabel,
  offer,
  weights,
  handleSelectedWeight,
  isShowWeightSelector,
  maxCartLimit,
  discountPrice,
}: TProps) => {
  const [isMobile] = useMediaQuery(mobile);
  const tags = thc?.split(',').map((tag) => tag.trim());
  const { currentDispensary } = useDispensary();
  const storeId = currentDispensary?.dispensaries?.storeId;
  const store_id = parseInt(storeId as string);
  const price_id = parseFloat(price);

  const discount_price = parseFloat(discountPrice as string);
  const cartProps = {
    tags: tags || [], // Ensure that tags is always an array
    position: position,
    defaultWeightSelect: defaultWeightSelect,
    productId: productId,
    placement: order,
    storeId: store_id,
    brand: brandName,
    strain: name,
    originalPrice: price_id,
    price: discount_price,
    offerText: offer,
    kind: kind,
  };
  const triggeredFrom = SegementAddToCartTriggerSource.NON_PDP;
  const {
    selectedQuantity,
    selectedWeight,
    setSelectedWeight,
    isSelectedWeightInCart,
    setSelectedQuantity,
    handleCartClick,
    existingItem,
  } = useCartState(cartProps, triggeredFrom, onClose);
  const totalPrice = price_id * selectedQuantity;

  const handleWeightChange = async (item: TProductSize) => {
    handleSelectedWeight && handleSelectedWeight(item);

    if (item?.name !== selectedWeight) {
      setSelectedWeight(item?.name);
      setSelectedQuantity(1);
    }
  };

  return (
    <CardContainers className={containersStyle}>
      <CardVerticalBox>
        <CardVerticalImageBody>
          <CardVerticalImageBox>
            <div className="flex">
              <IconButton onClick={onClose} button-type="primary" className="!bg-grey-200" color="white">
                <ChevronLeftIcon />
              </IconButton>
            </div>
            {strainsLabel && (
              <div className="flex">
                <Label variant="white-border">{strainsLabel}</Label>
              </div>
            )}
          </CardVerticalImageBox>
          <CardVerticalImage>
            <Image
              layout="fill"
              alt="product card"
              loader={(options) => options.src}
              src={image}
              className="h-full w-full object-cover lg:rounded-md"
              priority
            />
          </CardVerticalImage>
        </CardVerticalImageBody>
        <CardVerticalInfoBox>
          <ProductCardTypography variant="brand" text={brandName} />
          <ProductCardTypography variant="name" text={name} />
        </CardVerticalInfoBox>
      </CardVerticalBox>
      <CardVerticalBox>
        {isShowWeightSelector && (
          <React.Fragment>
            {weights && weights?.length > 1 && (
              <Typography variant="body-small-bold" className="!text-grey-480">
                Select weight
              </Typography>
            )}
            <CardVerticalWeightBox>
              {weights?.map((item: TProductSize) => (
                <WeightSelectorButton
                  key={item?.name}
                  label={item?.label || ''}
                  size={!isMobile ? 'lg' : 'sm'}
                  className="flex h-12 py-2 lg:py-3.5"
                  onClick={() => handleWeightChange(item)}
                  defaultSelected={item?.name === selectedWeight}
                  isMultiSelect={false}
                />
              ))}
            </CardVerticalWeightBox>
          </React.Fragment>
        )}
        <QuantitySelector
          maxLimit={maxCartLimit}
          onChange={(value: number) => {
            setSelectedQuantity(value);
          }}
          initialCount={selectedQuantity}
          quantityContainerStyle={'mb-4 md:mb-5 mb-4'}
          existingItemQuantity={existingItem.quantity}
        />
        <CommonButton
          button-type="primary"
          disabled={isSelectedWeightInCart && existingItem.quantity === selectedQuantity ? true : false}
          onClick={handleCartClick}
          full-width
          color="green"
        >
          <Typography className="!text-white" variant="body-small-bold">
            {!existingItem.quantity || (isSelectedWeightInCart && existingItem.quantity === selectedQuantity)
              ? `Confirm $${Number(totalPrice)?.toFixed(2)}`
              : selectedQuantity === 0
              ? 'Remove Item'
              : `Update Cart $${Number(totalPrice)?.toFixed(2)}`}
          </Typography>
        </CommonButton>
      </CardVerticalBox>
    </CardContainers>
  );
};

export default ProductCardConfirmVertical;
