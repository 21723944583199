'use client';

import React from 'react';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { THeaderShopUrlEnums } from 'helpers/types/organisms/global/header';
import { getMenuFiltersLink } from 'helpers/utils/common';
import { desktop } from 'helpers/utils/screensizes';
import { CommonNavigation } from '../header-dispensaries/types/common';
import { MenuExtendedType } from '../header-dispensaries/types/menu-extended';
import { HeaderDataType } from '../header-dispensaries/types/navigation';
import HeaderShopListDesktop from './header-shop-list/header-shop-list-desktop';
import HeaderShopListMobile from './header-shop-list/header-shop-list-mobile';
import { HeaderShopWrapper } from './styles';

type HeaderShopProps = {
  toggleModal: (name: string) => void;
  modal?: MenuExtendedType;
  data?: HeaderDataType;
};

const HeaderShop = ({ toggleModal, modal, data }: HeaderShopProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  const { currentDispensary, host } = useDispensary();

  const stateDealsLink = `${host}/dispensaries/${currentDispensary?.state?.slug.split('/')[2]}/deals`;
  const modalData = modal?.shop?.data as CommonNavigation[];

  (modalData || []).forEach((links) => {
    if (links.navigationUrl === THeaderShopUrlEnums.DEAL) {
      links.navigationUrl = stateDealsLink;
    }

    if (links.navigationUrl === THeaderShopUrlEnums.MENU) {
      links.navigationUrl = getMenuFiltersLink();
    }

    switch (links.navigationUrl) {
      case THeaderShopUrlEnums.CATEGORIES:
      case THeaderShopUrlEnums.BRANDS:
      case THeaderShopUrlEnums.USAGES:
        links['isDrawer'] = true;
        break;
      default:
        links['isDrawer'] = false;
        break;
    }
  });

  return (
    <HeaderShopWrapper data-testid="header-shop">
      {isDesktop ? (
        <HeaderShopListDesktop data={data} modalShopData={modalData} />
      ) : (
        <HeaderShopListMobile toggleModal={toggleModal} modalShopData={modalData} />
      )}
    </HeaderShopWrapper>
  );
};

export default HeaderShop;
