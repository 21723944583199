import { ICart } from 'helpers/types';
import { SegmentEvents } from '../constants/forms';
import { ICartViewedEvent, SegementTrackEvent } from './segment-helper';

export enum SegementCartTriggerSource {
  PRIMARY_NAV = 'primary-nav',
  SNACKBAR = 'snackbar',
}
interface ISegmentEvent {
  cartTrigger: SegementCartTriggerSource;
  cart: ICart;
  subTotal: number;
  discountTotal: number;
}

export const cartClicked = (value: ISegmentEvent) => {
  try {
    const data: ICartViewedEvent = {
      cart_trigger: value?.cartTrigger,
      cart_uuid: value?.cart?.cart_uuid,
      cart_empty: Object.keys(value?.cart?.items).length === 0 ? true : false,
      cart_subtotal: `${value?.subTotal}`,
      //TODO: based on Jane response this value need to be implemented.
      // cart_estimated_total: `${value.discountTotal || value.subTotal}`,
      cart_estimated_total: `${0}`,
      cart_savings: `${value?.subTotal - value?.discountTotal || 0}`,
    };
    return SegementTrackEvent(SegmentEvents.CART_VIEWED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
