'use client';

import React from 'react';
import { Typography } from 'components/atoms/typography';
import SimpleImageOrVideo from 'components/molecules/image-or-video/simple-image-or-video';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBannerStatementProps } from 'helpers/types/organisms/banners/banner-statement';
import { tablet } from 'helpers/utils/screensizes';
import {
  BSDesktopContainer,
  BSDesktopContent,
  BSDesktopLeftImg,
  BSDesktopRightImg,
  BSDesktopRightImgBox,
  BSImageContainerDesktop,
  BSImageContainerMobile,
  BSMobileContainer,
  BSMobileContent,
  BSMobileLeftImg,
  BSMobileRightImg,
  BSQuoteWrapperDesktop,
  BSQuoteWrapperMobile,
  QuotesBox,
  QuotesSection,
} from './styles';

const BannerContentLong = ({
  leftSideImage,
  rightSideImage,
  overlayImage,
  cannabisQuotesCollection,
}: TBannerStatementProps) => {
  const quotes = cannabisQuotesCollection?.items || [];
  const [isTablet] = useMediaQuery(tablet);

  return (
    <>
      {!isTablet ? (
        <BSMobileContainer data-automationid="c10_banner_01" data-testId="mobile-view">
          <BSMobileLeftImg>
            {rightSideImage?.mobileImage?.url && <SimpleImageOrVideo imageOrVideo={rightSideImage?.mobileImage} />}
          </BSMobileLeftImg>
          <BSMobileContent>
            <BSQuoteWrapperMobile>
              <QuotesSection>
                {Array.isArray(quotes) &&
                  quotes?.slice(0, 2)?.map((item, index) => (
                    <QuotesBox key={index}>
                      {item?.quotes &&
                        item?.quotes?.map((item, index) => (
                          <Typography
                            variant="h4"
                            className="text-center uppercase odd:!text-leaf even:!text-kief"
                            key={index}
                          >
                            {item}
                          </Typography>
                        ))}
                    </QuotesBox>
                  ))}
              </QuotesSection>
              <BSImageContainerMobile>
                {overlayImage?.mobileImage?.url && (
                  <SimpleImageOrVideo
                    imageOrVideo={overlayImage?.mobileImage}
                    imageProps={{ className: 'h-full w-full' }}
                  />
                )}
              </BSImageContainerMobile>
              <QuotesSection>
                {Array.isArray(quotes) &&
                  quotes?.slice(2, 4)?.map((item, index) => (
                    <QuotesBox key={index}>
                      {item?.quotes &&
                        item?.quotes?.map((item, index) => (
                          <Typography
                            variant="h4"
                            className="text-center uppercase odd:!text-leaf even:!text-kief"
                            key={index}
                          >
                            {item}
                          </Typography>
                        ))}
                    </QuotesBox>
                  ))}
              </QuotesSection>
            </BSQuoteWrapperMobile>
          </BSMobileContent>
          <BSMobileRightImg>
            {leftSideImage?.mobileImage?.url && <SimpleImageOrVideo imageOrVideo={leftSideImage?.mobileImage} />}
          </BSMobileRightImg>
        </BSMobileContainer>
      ) : (
        <BSDesktopContainer data-automationid="c10_banner_01" data-testId="tablet-view">
          <BSDesktopContent>
            <BSDesktopLeftImg data-testid="desktop-image">
              {leftSideImage?.desktopImage?.url && <SimpleImageOrVideo imageOrVideo={leftSideImage?.desktopImage} />}
            </BSDesktopLeftImg>
            <BSQuoteWrapperDesktop>
              <QuotesSection>
                {Array.isArray(quotes) &&
                  quotes?.slice(0, 2)?.map((item, index) => (
                    <QuotesBox key={index}>
                      {item?.quotes &&
                        item?.quotes?.map((item, index) => (
                          <Typography
                            variant="h4"
                            className="text-center uppercase odd:!text-leaf even:!text-kief"
                            key={index}
                            data-testid="text"
                          >
                            {item}
                          </Typography>
                        ))}
                    </QuotesBox>
                  ))}
              </QuotesSection>
              <BSImageContainerDesktop>
                {overlayImage?.desktopImage?.url && (
                  <SimpleImageOrVideo
                    imageOrVideo={overlayImage?.desktopImage}
                    imageProps={{ className: 'h-full w-full' }}
                  />
                )}
              </BSImageContainerDesktop>
              <QuotesSection>
                {Array.isArray(quotes) &&
                  quotes?.slice(2, 4)?.map((item, index) => (
                    <QuotesBox key={index}>
                      {item?.quotes &&
                        item?.quotes?.map((item, index) => (
                          <Typography
                            variant="h4"
                            className="text-center uppercase odd:!text-leaf even:!text-kief"
                            key={index}
                          >
                            {item}
                          </Typography>
                        ))}
                    </QuotesBox>
                  ))}
              </QuotesSection>
            </BSQuoteWrapperDesktop>
          </BSDesktopContent>
          <BSDesktopRightImgBox>
            <BSDesktopRightImg>
              {rightSideImage?.desktopImage?.url && <SimpleImageOrVideo imageOrVideo={rightSideImage?.desktopImage} />}
            </BSDesktopRightImg>
          </BSDesktopRightImgBox>
        </BSDesktopContainer>
      )}
    </>
  );
};

export default BannerContentLong;
