import React from 'react';
import Image from 'next/image';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { TContenfulImageProp } from 'helpers/types';
import { THeaderState } from 'helpers/types/organisms/global/header';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { DispensariesData } from '../../types/dispensaries';
import { HeaderStateDesktopImageBox, HeaderStateDesktopList, HeaderStateDesktopWrapper } from './styles';

type HeaderStateDesktopProps = {
  data?: DispensariesData[];
  image: TContenfulImageProp;
  toggleModal: (name: string, data: DispensariesData) => void;
  onPressLink?: (name: string) => void;
};

const HeaderStateDesktop = ({ toggleModal, data, image, onPressLink }: HeaderStateDesktopProps) => {
  return (
    <HeaderStateDesktopWrapper data-testid="header-state-desktop">
      <HeaderStateDesktopList>
        {data &&
          data.map((item, index) => (
            <li key={index}>
              <Typography
                className="flex w-fit cursor-pointer flex-row items-center gap-5 !text-white opacity-40 hover:opacity-100 [&_button]:hover:visible"
                variant="h3"
                as="a"
                onClick={() => {
                  onPressLink && onPressLink(item?.state?.state?.stateName),
                    toggleModal(THeaderState.CHANGEDDISPENSARY, item);
                }}
              >
                {item?.state?.state?.stateName}
                <IconButton
                  className="invisible mt-3 bg-leaf-dark !text-kief hover:!text-kief"
                  color="green"
                  button-type="secondary"
                >
                  <ChevronRightIcon />
                </IconButton>
              </Typography>
            </li>
          ))}
      </HeaderStateDesktopList>
      {image && (
        <HeaderStateDesktopImageBox>
          <Image
            alt={image?.alt || ''}
            width={image?.height}
            height={image?.width}
            src={image?.url}
            loader={(res) => res.src}
          />
        </HeaderStateDesktopImageBox>
      )}
    </HeaderStateDesktopWrapper>
  );
};

export default HeaderStateDesktop;
