'use client';

import Footer from 'components/organisms/global/footer/index';
import { getMenuLink } from 'helpers/utils/common';
// import { getMenuLink } from 'helpers/utils/navigation/generate-menu-links';

const FooterComponent = ({ data, dispensaryData, pageFolder }) => {
  // TODO: migrate this to client component
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setChatModalData(sectionSupportChatCollection));
  // }, []);

  if (!data?.footerMenu || data?.footerMenu?.dataSource?.isError) {
    return <></>;
  }
  const sectionFooterCollection = data?.footerMenu?.dataSource?.data?.sectionFooterCollection?.items?.[0] || {};
  const sectionSupportChat = data?.footerMenu?.dataSource?.data?.sectionSupportChatCollection?.items?.[0] || {};
  const logo = sectionFooterCollection?.logo?.url;
  const logoAltText = sectionFooterCollection?.logo?.title;

  const backgroundObjectDesktop = {
    file: sectionFooterCollection?.backgroundImage?.desktopImage?.url,
    name: sectionFooterCollection?.backgroundImage?.desktopImage?.title,
    width: sectionFooterCollection?.backgroundImage?.desktopImage?.width,
    height: sectionFooterCollection?.backgroundImage?.desktopImage?.height,
  };

  const backgroundObjectMobile = {
    file: sectionFooterCollection?.backgroundImage?.mobileImage?.url,
    name: sectionFooterCollection?.backgroundImage?.mobileImage?.title,
    width: sectionFooterCollection?.backgroundImage?.mobileImage?.width,
    height: sectionFooterCollection?.backgroundImage?.mobileImage?.height,
  };

  const logoObject = {
    file: logo,
    name: logoAltText,
    width: sectionFooterCollection?.logo?.width,
    height: sectionFooterCollection?.logo?.height,
  };

  const legalCopyRight = sectionFooterCollection?.copyrightText;
  const chatCta = sectionFooterCollection?.chatCta?.title;
  const dispensaryDataFromCollection = data?.footerMenu?.dataSource?.data?.pageDispensaryCollection?.items[0];
  const customerCenterData = data?.footerMenu?.dataSource?.data?.pageContactUsCollection?.items[0];
  const sectionSupportChatCollection = sectionSupportChat;
  const gtiLogo = sectionFooterCollection?.gtiLogo;

  const values = {
    storeId: dispensaryData?.storeId,
    slug: dispensaryDataFromCollection?.slug,
    menusCollection: dispensaryDataFromCollection?.menusCollection || [],
    dispensaryTags: dispensaryDataFromCollection?.dispenaryTags || [],
  };

  // const shopUrl = getMenuLink();

  return (
    <Footer
      licenseData={dispensaryDataFromCollection}
      legalCopyRight={legalCopyRight}
      logo={logoObject}
      host={pageFolder?.host}
      backgroundImageDesktop={backgroundObjectDesktop}
      backgroundImageMobile={backgroundObjectMobile}
      chatCta={chatCta}
      socialMediaUrls={sectionFooterCollection?.socialMediaPresence}
      homeNavigationMenu={sectionFooterCollection?.navigationMenuAboutCollection?.items}
      tocNavigationMenu={sectionFooterCollection?.navigationMenuLegalCollection?.items}
      customerCenterData={customerCenterData}
      gtiLogo={gtiLogo}
      shopUrl={'test'}
    />
  );
};

export default FooterComponent;
