import React from 'react';
import { Typography } from 'components/atoms/typography';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { TContenfulImageProp } from 'helpers/types';
import { BigImageContainer } from './styles';
type TProps = {
  title?: string;
  image: TContenfulImageProp;
  alignment?: boolean;
  isTablet?: boolean;
  isMediumDesktop?: boolean;
};
const BigImage = ({ title, image, alignment, isTablet, isMediumDesktop }: TProps) => {
  return (
    <BigImageContainer $alignment={alignment}>
      <ResponsiveImageOrVideo
        desktop={image}
        mobile={image}
        imageProps={{
          imageHeight: isMediumDesktop ? 606 : isTablet ? 381 : 424.8,
          imageWidth: isMediumDesktop ? 1192 : isTablet ? 742 : 767,
          className: 'md:rounded-md',
          // TODO: review why we are using title instead of alt
          alt: image?.title || '',
        }}
      />
      {title && (
        <Typography variant="body-small" className="mx-6 border-b border-grey-200 py-6 lg:mx-0 lg:py-6">
          {title}
        </Typography>
      )}
    </BigImageContainer>
  );
};

export default BigImage;
