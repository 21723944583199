import tw from 'tailwind-styled-components';

export const FilterPillButtonWrapper = tw.div`
    flex
    sm:flex-nowrap
    md:flex-wrap
    gap-3
`;

export const ScrolledContainer = tw.div<{ $isFixed?: boolean }>`
    flex
    flex-col
    gap-10
    px-5
    py-4
    md:gap-16
    md:px-[60px]
    md:py-10
    ${(p) => (p.$isFixed ? 'fixed bg-white w-full z-50' : 'hidden')}
`;

export const WithoutScrollContainer = tw.div<{ $isInvisible?: boolean }>`
    flex
    flex-col
    gap-10
    px-5
    py-10
    md:gap-16
    md:px-[60px]
    md:py-20
    overflow-hidden
    ${(p) => (p.$isInvisible ? 'invisible' : 'visible')}

`;

export const ScrolledFilterBox = tw.div`
    flex
    flex-col
    items-start
    justify-between
    gap-10
    lg:flex-row
`;

export const WithoutScrollFilterBox = tw.div`
    flex
    flex-col
    items-start
    justify-between
    gap-10
    lg:flex-row
    [&_div.slick-track]:pr-3
`;

export const SearchHeadingWrapper = tw.div`
    flex
    flex-col
    gap-6
    md:gap-16
`;

export const SliderWrapper = tw.div`
    flex
`;

export const FilterCarouselContainer = tw.div`
    relative
    contents
    [&_div.slick-slide]:md:mr-3
    [&_div.slick-slide]:mr-2.5
`;

export const PreviousIconContainer = tw.div`
    absolute
    left-0
    z-10
    flex
`;

export const PreviousIconWrapper = tw.div`
    flex
    h-10
    w-10
    items-center
    justify-center
    bg-white
`;

export const PreviousIconGredient = tw.div`
    h-10
    w-10
    bg-gradient-to-r
    from-white
    to-white-color-100
`;

export const NextIconContainer = tw.div`
    absolute
    right-0
    z-10
    flex
`;

export const NextIconGredient = tw.div`
    h-10
    w-10
    bg-gradient-to-l
    from-white
    to-white-color-100
`;

export const NextIconWrapper = tw.div`
    flex
    h-10
    w-10
    items-center
    justify-center
    bg-white
`;
