'use client';

import { useEffect, useRef, useState } from 'react';
import { Drawer } from '@material-tailwind/react';
import { Typography } from 'components/atoms/typography';
import { sortSelect } from 'helpers/analytics/sort-select-event';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screenSizes from 'helpers/utils/screensizes';
import CheckIcon from 'public/icons/figma/check.svg';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';
import CloseIcon from 'public/icons/figma/close-small.svg';
import { IconWithTextButton } from '../icon-button/icon-with-text';
import {
  BottomModal,
  Container,
  List,
  ListContainer,
  ListHeader,
  ListItem,
  MobileListItem,
  OuterContainer,
} from './styles';

interface IProps {
  label: string;
  options: string[];
  onSelect?(value): void;
  className?: string;
  placement?: number;
  card_list_name?: string;
  card_list_title?: string;
  card_list_type?: string;
  sortValue?: string;
}

export const SortingButton = (props: IProps) => {
  const { options, label, onSelect, sortValue } = props;
  const [selectedOption, setOption] = useState(sortValue || options[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const modalRef = useRef<HTMLDivElement>(null);

  const handelOnSelect = (item) => {
    sortSelect({
      placement: props.placement as number,
      card_list_name: props.card_list_name as string,
      card_list_title: props.card_list_title as string,
      card_list_type: props.card_list_type as string,
      sorted_by: `${item}`,
    });
    setOption(item);
    if (onSelect) onSelect(item);
    setIsOpen(!isOpen);
  };

  const closePopUp = (e: MouseEvent) => {
    e.preventDefault();
    if (modalRef.current && !modalRef.current.contains(e.target as Node | null)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mouseup', closePopUp);
    return () => {
      window?.removeEventListener('mouseup', closePopUp);
    };
  }, []);

  const DesktopListView = () => {
    return (
      <List data-testid="desktop-view">
        {options.map((item) => (
          <ListContainer key={item} onClick={() => handelOnSelect(item)} $isSelected={selectedOption === item}>
            <ListItem $isSelected={selectedOption === item}>{item}</ListItem>
            {selectedOption === item && <CheckIcon />}
          </ListContainer>
        ))}
      </List>
    );
  };

  const MobileListView = () => {
    return (
      <BottomModal data-testid="mobile-view">
        <Drawer open={isOpen} onClose={() => setIsOpen(!isOpen)} className="!max-h-[280px]">
          <ListHeader>
            <Typography variant="body-small" className="!text-grey-400">
              {label}
            </Typography>
            <CloseIcon
              className="aspect-square w-6 shrink-0 fill-grey-400 object-cover object-center "
              onClick={() => setIsOpen(!isOpen)}
            />
          </ListHeader>
          {options &&
            options.map((item) => (
              <MobileListItem key={item} onClick={() => handelOnSelect(item)} $isSelected={selectedOption === item}>
                <ListItem $isSelected={selectedOption === item}>
                  <Typography variant={selectedOption === item ? 'body-small-bold' : 'body-small'}>{item}</Typography>
                </ListItem>
                {selectedOption === item && <CheckIcon />}
              </MobileListItem>
            ))}
        </Drawer>
      </BottomModal>
    );
  };
  return (
    <OuterContainer ref={modalRef}>
      <Typography variant="body-small" className="mt-3 !text-grey-400">
        {label}
      </Typography>
      <Container>
        <IconWithTextButton
          color="green"
          button-type="primary"
          iconPosition="after"
          icon={
            isOpen ? (
              <ChevronUpIcon className="m-auto fill-white " data-testid="up-icon" />
            ) : (
              <ChevronDownIcon className="m-auto fill-white" data-testid="down-icon" />
            )
          }
          onClick={(e: any) => {
            e?.preventDefault();
            setIsOpen(!isOpen);
          }}
          className={`justify-between !py-3 !pl-5 !pr-[18px] hover:!text-white lg:!py-[14px] lg:!pl-6 lg:pr-[18px] ${
            isOpen ? 'min-w-[160px] md:rounded-b-[0px] md:rounded-t-[33px]' : 'min-w-[160px]'
          }`}
        >
          {selectedOption}
        </IconWithTextButton>
        {isOpen && (isBiggerThanMobile ? <DesktopListView /> : <MobileListView />)}
      </Container>
    </OuterContainer>
  );
};
