import tw from 'tailwind-styled-components';

export const FooterContainer = tw.div`
  mx-auto
  grid
  grid-cols-1
`;

export const FooterMainContainer = tw.div`
  bg-grey-100
  flex
  flex-col
  px-5
  py-10
  md:px-[60px]
  md:pt-[80px]
  lg:grid
  lg:h-[640px]
  lg:grid-cols-2
  lg:gap-6
  lg:pt-[80px]
  xl:grid
  xl:grid-cols-2
  xl:gap-8
`;

export const CopyRightTextAnchor = tw.button`
  ml-2
  cursor-default
  tracking-[0.14px]
  [&_a]:cursor-pointer
`;

export const FooterLeftSideContainer = tw.div`
  gap-4
  lg:grid
  lg:grid-cols-3
  xl:grid-cols-3
  xl:gap-4
`;

export const RiseLogoContainer = tw.div`
  flex
  mb-8
  h-[72px]
  lg:h-[80px]
  lg:col-span-1
  lg:col-span-1
  lg:justify-center
  xl:col-span-1
`;

export const HomeNavigationContainer = tw.div`
  relative
  flex
  mb-8
  w-full
  flex-shrink-0
  flex-grow-0
  overflow-visible
  lg:col-span-2
  xl:col-span-2
  xl:w-[432px]
  lg:overflow-hidden
`;

export const FooterRightSideContainer = tw.div`
  lg:flex
  lg:flex-row
  xl:col-span-1
  xl:grid
  xl:grid-cols-3
`;

export const TocNavigationContainer = tw.div`
  font-arial
  mb-8
  lg:ml-10
  lg:pl-6
  xl:col-span-2
`;

export const FooterChatIconContainer = tw.div`
  xl:col-span-1
  lg:ustify-items-end
  flex
  flex-col
  lg:items-end
`;

export const SocialIconsContainer = tw.div`
  w-full
  mb-8
  max-w-[168px]
`;

export const ChatButton = tw.button`
  w-[200px]
  flex
  items-center
  justify-center
  rounded-[32px]
  border
  border-leaf
  font-arial
  font-bold
  text-leaf
  h-[48px]
  py-[22px]
  pr-[38px]
  pl-[32px]
  lg:h-[64px]
  lg:py-[16px]
  lg:pr-[26px]
  lg:pl-[22px]
  lg:ml-2
`;

export const FooterCopyRightSpan = tw.div`
flex
font-arial
font-normal
text-light
text-[14px]
leading-4
tracking-[0.14px]
lg:px-[60px]
lg:text-[16px]
lg:leading-5
lg:tracking-[0.16px]
`;

export const FooterCopySection = tw.div`
flex
flex-col
justify-between
bg-leaf
p-5
md:flex-row
`;

export const ImageContainer = tw.div`
mt-3
md:mt-0`;

export const FooterTocMenuItem = tw.li`
flex
flex-shrink-0
flex-grow-0
flex-nowrap
mb-6
text-grey-400
hover:text-leaf
cursor-pointer
`;

export const FooterTocContainer = tw.ul`
lg:ml-6
lg:px-5 `;

export const FooterHomeNavigationContainer = tw.div`
w-full
`;
export const FooterHomeMenuContainer = tw.ul`
order-2
w-full
border-t-2
border-grey-300`;

export const FooterHomeMenuItem = tw.li`
inline-flex
w-full
cursor-pointer
border-b-2
border-[#BDC2A6]
py-4
lg:py-5
`;
export const FooterHomeMenuItemContainer = tw.div`
flex
w-fit
md:[&_button]:hover:inline-flex`;
export const JointLogo = tw.div`
absolute
top-0
left-[13rem]
lg:left-[18rem]
lg:top-[2rem]
flex
order-1`;

export const LicenseWrapper = tw.div`
  bg-grey-100
  py-6
  px-5
  border-y-solid
  border-y-[1px]
  border-y-grey-200
`;

export const IdContainer = tw.div`
grid-row-2
font-arial
font-normal
text-grey-400
grid
pt-2
text-[14px]
leading-4
tracking-[0.14px]
lg:flex
lg:flex-row
lg:pt-0
lg:text-[16px]
lg:leading-5
lg:tracking-[0.16px]
`;
