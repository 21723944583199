import React from 'react';
import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IRichTextData } from 'helpers/types';
import { ReplaceUrlWithHost, getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import { TImage } from '..';
import {
  BannerSpecialOfferBody,
  BannerSpecialOfferContainer,
  BannerSpecialOfferContent,
  BannerSpecialOfferContentWrapper,
  BannerSpecialOfferImageWrapper,
} from './styles';

type TBannerSpecialOfferProps = {
  title?: string;
  description?: IRichTextData;
  subTitle?: string;
  image?: TImage;
  richTextCustom?: boolean;
  overlayImage?: TImage;
  handleSegmentEvent?: (text?: string) => void;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
};

const BannerSpecialOffer = ({
  title,
  description,
  image,
  richTextCustom,
  ctaCollection,
  subTitle,
  host,
  handleSegmentEvent,
}: TBannerSpecialOfferProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [isMediumDesktop] = useMediaQuery(mediumDesktop);
  const paersedCtaUrl = ctaCollection?.longUrl ? ctaCollection?.longUrl : ctaCollection?.url;
  const ctaUrl = ReplaceUrlWithHost(paersedCtaUrl || '{{url}}/', host as string);
  const newUrl = getPathMenuFiltersLink(ctaUrl);
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <BannerSpecialOfferContainer ref={targetRef} $richTextCustom={richTextCustom} data-automationid="c11_banner_b_00">
      <BannerSpecialOfferBody>
        <BannerSpecialOfferContentWrapper>
          {subTitle && <Typography variant="body-small">{subTitle}</Typography>}
          <BannerSpecialOfferContent>
            {title && (
              <Typography variant="h2" className="text-center md:text-left">
                {title}
              </Typography>
            )}
            {description && (
              <ContentfulRichText
                data={description}
                renderNodeOptions={{
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <Typography variant="body-large" className="text-center md:text-left">
                      {children}
                    </Typography>
                  ),
                }}
              />
            )}
          </BannerSpecialOfferContent>
          <CommonButton
            button-type="primary"
            href={newUrl}
            target={getLinkTarget(newUrl)}
            as="a"
            size="lg"
            color="green"
            onClick={() => handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
            className="!text-light"
          >
            {ctaCollection.title}
          </CommonButton>
        </BannerSpecialOfferContentWrapper>
        <BannerSpecialOfferImageWrapper>
          {(image?.mobileImage?.url || image?.desktopImage?.url) && (
            <Image
              src={!isTablet && image?.mobileImage ? image?.mobileImage?.url || '' : image?.desktopImage?.url || ''}
              alt={!isTablet && image?.mobileImage ? image?.mobileImage?.title || '' : image?.desktopImage?.title || ''}
              loader={(options) => options?.src}
              width={isMediumDesktop ? 1013 : isTablet ? 725 : 727}
              height={isMediumDesktop ? 540 : isTablet ? 388 : 523}
            />
          )}
        </BannerSpecialOfferImageWrapper>
      </BannerSpecialOfferBody>
    </BannerSpecialOfferContainer>
  );
};

export default BannerSpecialOffer;
