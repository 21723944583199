import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import {
  FooterHomeMenuContainer,
  FooterHomeMenuItem,
  FooterHomeMenuItemContainer,
  FooterHomeNavigationContainer,
  JointLogo,
} from './styles';

type FooterMenuProps = {
  homeNavigationMenu: Array<{
    navigationLabel: string;
    navigationUrl: string;
  }>;
  backgroundImageDesktop: {
    file: string;
    name: string;
    width: number;
    height: number;
  };
  backgroundImageMobile: {
    file: string;
    name: string;
    width: number;
    height: number;
  };
  host: string;
  shopUrl: string;
};

const HomeNavigation: FunctionComponent<FooterMenuProps> = ({
  homeNavigationMenu,
  backgroundImageDesktop,
  backgroundImageMobile,
  host,
  shopUrl,
}) => {
  const isTablet = useMediaQuery(tablet);
  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'footer',
      link_primary: name,
      link_type: 'hyperlink',
    });
  };

  return (
    <FooterHomeNavigationContainer>
      <FooterHomeMenuContainer>
        {Array.isArray(homeNavigationMenu) &&
          homeNavigationMenu?.map((menuItem, index) => {
            const url =
              menuItem?.navigationUrl === '#shop' ? shopUrl : ReplaceUrlWithHost(menuItem?.navigationUrl, host);
            return (
              <FooterHomeMenuItem key={index}>
                <FooterHomeMenuItemContainer>
                  <Typography
                    variant="h4"
                    as="a"
                    onClick={() => handleOnPressLink(menuItem?.navigationLabel)}
                    target={getLinkTarget(url)}
                    className="align-center flex"
                    href={url}
                  >
                    {menuItem?.navigationLabel}
                    <IconButton className="ml-5 hidden !p-2" color="green" button-type="secondary">
                      <ChevronRightIcon />
                    </IconButton>
                  </Typography>
                </FooterHomeMenuItemContainer>
              </FooterHomeMenuItem>
            );
          })}
      </FooterHomeMenuContainer>
      <JointLogo>
        <Image
          src={isTablet ? backgroundImageDesktop.file : backgroundImageMobile.file}
          alt={isTablet ? backgroundImageDesktop.name : backgroundImageMobile.name}
          width={isTablet ? backgroundImageDesktop.width : backgroundImageMobile.width}
          height={isTablet ? backgroundImageDesktop.height : backgroundImageMobile.height}
          loader={(options) => options.src}
        />
      </JointLogo>
    </FooterHomeNavigationContainer>
  );
};

export default HomeNavigation;
