import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import {
  HeaderCannabisInsiderMobileBox,
  HeaderCannabisInsiderMobileList,
  HeaderCannabisInsiderMobileListItem,
  HeaderCannabisInsiderMobileListLink,
  HeaderCannabisInsiderMobileWrapper,
} from './styles';

type TProps = {
  toggleModal: (title: string) => void;
  data: any;
  onPressLink?: (name: string) => void;
  host?: string;
};

const HeaderCannabisInsiderMobile = ({ toggleModal, data, onPressLink, host }: TProps) => {
  return (
    <HeaderCannabisInsiderMobileWrapper>
      <HeaderCannabisInsiderMobileBox>
        <IconButton
          button-type="primary"
          className="!bg-kief !text-leaf"
          color="green"
          onClick={() => toggleModal(THeaderState.NAVBAR)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h2" className="!text-kief">
          {data?.name || ''}
        </Typography>
      </HeaderCannabisInsiderMobileBox>
      <HeaderCannabisInsiderMobileList>
        {data &&
          data?.data?.map((item, index) => (
            <HeaderCannabisInsiderMobileListItem key={index}>
              <HeaderCannabisInsiderMobileListLink
                onClick={() => onPressLink && onPressLink(item?.navigationLabel)}
                target={getLinkTarget(ReplaceUrlWithHost(item?.navigationUrl, host as string))}
                href={ReplaceUrlWithHost(item?.navigationUrl, host as string)}
              >
                <Typography variant="h5" className="text-white">
                  {item?.navigationLabel}
                </Typography>
              </HeaderCannabisInsiderMobileListLink>
            </HeaderCannabisInsiderMobileListItem>
          ))}
      </HeaderCannabisInsiderMobileList>
    </HeaderCannabisInsiderMobileWrapper>
  );
};

export default HeaderCannabisInsiderMobile;
