import tw from 'tailwind-styled-components';

export const EditQuantityCountStyle = tw.div`
    text-arial 
    h-[20px]  
    w-[20px] 
    ml-2
    rounded-[9px] 
    bg-resin 
    py-[3px] 
    text-xs 
    font-bold 
    text-white
`;

export const CardVerticalContainers = tw.a`
    relative 
    flex 
    h-[440px] 
    flex-col 
    rounded-md 
    border 
    border-solid 
    border-grey-300
    bg-white 
    w-full
    max-w-[260px]
    md:min-w-[230px]
    md:max-w-[400px]
    md:h-[594px]
    xl:min-w-[230px]
    xl:max-w-[432px]
    xl:h-[666px]
    justify-between
`;
export const CardVerticalImageBox = tw.div`
    relative 
    h-[204px] 
    overflow-hidden 
    sm:p-0 
    md:h-[320px]
    md:min-w-[195px]
    md:max-w-[368px]
    xl:max-w-[400px]
    md:mx-4 
    md:mt-4
`;
export const CardVerticalBodyBox = tw.div`
    px-4 
    pb-4 
    pt-3 
    md:pt-2.5
    xl:px-8 
    xl:pb-8 
    xl:pt-5
`;
export const CardVerticalBodyTitleBox = tw.div`
    mb-3 
    border-b 
    border-solid 
    border-grey-200 
    pb-3 
    md:mb-2.5
    md:pb-2.5
    xl:mb-4 
    xl:pb-5
`;

export const CardVerticalBodyGramPriceBox = tw.div`
    flex
    flex-row
    items-center
    justify-end
    pb-5
    md:pb-2.5
    xl:pb-5
`;
export const CardVerticalBodyPriceBox = tw.div`
    flex 
    flex-row 
    items-center
`;
