'use client';

import { useState } from 'react';
import dynamic from 'next/dynamic';
import LocalizationIndicator from 'components/organisms/global/localization-indicatior';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { smallMobile, tablet } from 'helpers/utils/screensizes';

type TProps = {
  data: {
    hideOnMobile: boolean;
  };
  pageFolder: any;
};

const GeolocationTastics = ({ data, pageFolder }: TProps) => {
  const DispensaryDrawer = dynamic(() => import('components/organisms/drawers/dispensary-drawer'));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { hideOnMobile } = data || {};
  const [isMobile] = useMediaQuery(smallMobile);
  const [isTablet] = useMediaQuery(tablet);
  const { currentDispensary } = useDispensary();

  return (
    <>
      {((!hideOnMobile && isMobile) || isTablet) && (
        <>
          <LocalizationIndicator
            locationName={currentDispensary?.dispensaries?.dispensaryInformation.title}
            setDrawerOpen={setIsOpen}
          />
          {isOpen && <DispensaryDrawer isOpen={isOpen} setDrawerOpen={setIsOpen} host={pageFolder?.host} />}
        </>
      )}
    </>
  );
};

export default GeolocationTastics;
