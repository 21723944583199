import React from 'react';
import { Typography } from 'components/atoms/typography';
import { IdContainer, LicenseWrapper } from 'components/organisms/global/footer/styles';

interface FooterLicenseProps {
  licenceNumberRecreationalMenu?: string;
  loungeLicenseNumber?: string;
  licenceNumberMedicalMenu?: string;
  title?: string;
}

export const FooterLicense = ({
  licenceNumberRecreationalMenu,
  loungeLicenseNumber,
  licenceNumberMedicalMenu,
  title,
}: FooterLicenseProps) => {
  return (
    <LicenseWrapper>
      <Typography variant="small" className="mb-4 !font-bold !text-grey-400">
        {title} Licenses
      </Typography>
      <IdContainer>
        {licenceNumberRecreationalMenu && (
          <Typography
            variant="small"
            className={`${
              licenceNumberMedicalMenu || loungeLicenseNumber ? 'mb-2 lg:mb-0 lg:mr-[60px]' : ''
            } !text-grey-400`}
            data-testid="licenceNumberRecreationalMenu"
          >
            ADULT USE: {licenceNumberRecreationalMenu}
          </Typography>
        )}
        {licenceNumberMedicalMenu && (
          <Typography
            variant="small"
            className={`${loungeLicenseNumber ? 'mb-2 lg:mb-0 lg:mr-[60px]' : ''} !text-grey-400`}
            data-testid="licenceNumberMedicalMenu"
          >
            MED: {licenceNumberMedicalMenu}
          </Typography>
        )}
        {loungeLicenseNumber && (
          <Typography variant="small" className="!text-grey-400" data-testid="loungeLicenseNumber">
            LOUNGE: {loungeLicenseNumber}
          </Typography>
        )}
      </IdContainer>
    </LicenseWrapper>
  );
};

export default FooterLicense;
