import React from 'react';
import { TContenfulImageProp } from 'helpers/types';
import HeaderChangeDispensary from './header-change-dispensary';
import HeaderState from './header-state';
import { HeaderDispensariesWrapper } from './styles';
import { DispensariesData } from './types/dispensaries';
import { MenuExtendedType } from './types/menu-extended';

type TProps = {
  host: string;
  modal?: MenuExtendedType;
  data?: DispensariesData[];
  image: TContenfulImageProp;
  toggleModal: (name: string, data?: any) => void;
};

const HeaderDispensaries = ({ data, modal, toggleModal, image, host }: TProps) => {
  return (
    <HeaderDispensariesWrapper data-testid="header-dispensaries">
      {modal?.dispensaries?.isOpen && <HeaderState data={data} toggleModal={toggleModal} image={image} />}
      {modal?.changeDispensary?.isOpen && (
        <HeaderChangeDispensary
          data={modal?.changeDispensary.data as DispensariesData}
          toggleModal={toggleModal}
          image={image}
          host={host}
        />
      )}
    </HeaderDispensariesWrapper>
  );
};

export default HeaderDispensaries;
