import { ReactNode } from 'react';
import { Block, Inline } from '@contentful/rich-text-types';
import { Typography } from 'components/atoms/typography';
import { RichTextList, RichTextPragraphs, RichTextQuote } from './style';

export const withParagraph = (node: Block | Inline, children: ReactNode, CustomComponent?: React.FC<{ data: any }>) => {
  if (node.content.length === 1 && children && typeof children[0] === 'string' && children[0].trim() === '') {
    return <></>;
  } else if (CustomComponent) {
    return <CustomComponent data={children} />;
  } else if (node?.content?.find((item) => item?.nodeType === 'embedded-entry-inline')) {
    return <>{children}</>;
  } else {
    return <RichTextPragraphs variant="body-large">{children}</RichTextPragraphs>;
  }
};

export const withBlockQuotes = (node: Block | Inline, children: ReactNode) => {
  const blockquotesItem =
    Array.isArray(children) &&
    children.map((child) => {
      if (child?.props.variant === 'body-large') {
        return child.props.children;
      }
      return child;
    });
  return (
    <RichTextQuote>
      <Typography variant="h3" className="!text-grey-400">
        {blockquotesItem}
      </Typography>
    </RichTextQuote>
  );
};

export const withListItem = (node: Block | Inline, children: ReactNode, CustomComponent?: React.FC<{ data: any }>) => {
  const listItem =
    Array.isArray(children) &&
    children.map((child) => {
      if (child?.type === 'p') {
        return child.props.children;
      }
      return child;
    });

  if (CustomComponent) return <CustomComponent data={children} />;
  else return <RichTextList>{listItem}</RichTextList>;
};
