// IconButton.tsx
import React from 'react';
import { CommonButton, IButtonProps } from '../common-buttons/button';

interface IIconWithTextButton {
  icon: React.ReactNode;
  iconPosition: 'before' | 'after';
}

type IIconWithTextButtonProps = IIconWithTextButton & IButtonProps;

export const IconWithTextButton = ({
  icon,
  iconPosition,
  className,
  disabled,
  ...buttonProps
}: IIconWithTextButtonProps) => {
  const renderIcon = () => (
    <span className={`flex h-6 w-6 items-center justify-center ${disabled ? ' [&_svg_path]:!fill-grey-300' : ''}`}>
      {icon}
    </span>
  );

  const size =
    buttonProps?.size === 'sm'
      ? // small styles - before and after styles
        iconPosition === 'before'
        ? 'py-[14px] pr-6 pl-[18px] md:pr-6 md:pl-[18px'
        : 'py-[14px] pl-6 pr-[18px] md:pl-6 md:pr-[18px]'
      : // large styles - before and after styles
      iconPosition === 'before'
      ? 'md:py-[22px] md:pl-8 md:pr-[38px] py-4 pl-[22px] pr-[26px]'
      : 'md:py-[22px] md:pr-8 md:pl-[38px]  py-4 pr-[22px] pl-[26px]';

  return (
    <CommonButton
      {...buttonProps}
      disabled={disabled}
      className={`${className} inline-flex items-center ${
        buttonProps?.size === 'sm' ? 'gap-2 md:gap-[10px] xl:gap-2' : 'gap-2 md:gap-[10px] xl:gap-3'
      } ${buttonProps['button-type'] !== 'tertiary' ? size : 'gap-[6px]'} `}
    >
      {iconPosition === 'before' && renderIcon()}
      {buttonProps.children}
      {iconPosition === 'after' && renderIcon()}
    </CommonButton>
  );
};
