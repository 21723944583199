import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/atoms/typography';
import LocationIcon from 'public/icons/figma/location.svg';
import { RootState } from 'redux/store';
import { IconContainer, LocalizationIndicatorContainer } from './styles';

type TProps = {
  locationName: string;
  setDrawerOpen: (state: boolean) => void;
};

const LocalizationIndicator = ({ locationName, setDrawerOpen }: TProps) => {
  const [showDefault, setShowDefault] = useState(true);
  const { isChatMinimized, isChatOpen } = useSelector((state: RootState) => state.chat);

  let timer: number | undefined;
  let isHovering = false;

  useEffect(() => {
    const handleScroll = () => {
      if (!isChatMinimized && isChatOpen) {
        return;
      }

      const isAtTop = window.scrollY === 0;
      if (isAtTop) {
        setShowDefault(true);
      } else {
        setShowDefault(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isChatMinimized, isChatOpen]);

  const openDefault = () => {
    setShowDefault(true);
    clearTimeout(timer);
  };

  const hideDefault = () => {
    if (!isHovering && window.scrollY != 0) {
      // @ts-ignore
      timer = setTimeout(() => {
        setShowDefault(false);
      }, 150);
    }
  };
  const handleMouseEnter = () => {
    isHovering = true;
    openDefault();
  };

  const handleMouseLeave = () => {
    isHovering = false;
    hideDefault();
  };

  return showDefault ? (
    <LocalizationIndicatorContainer
      onMouseLeave={handleMouseLeave}
      onClick={() => setDrawerOpen(true)}
      $isChatMinimized={isChatMinimized}
      data-testid="location-container"
    >
      <LocationIcon fontSize={'20px'} className="self-end text-resin" />
      <Typography variant="body-small" className="ml-2 !text-grey-400 lg:ml-3">
        Shopping from
      </Typography>
      <Typography variant="body-small-bold" className="inline-block">
        &nbsp;{locationName}
      </Typography>
    </LocalizationIndicatorContainer>
  ) : (
    <IconContainer data-testid="icon-container" $isChatMinimized={isChatMinimized}>
      <LocationIcon
        onMouseEnter={handleMouseEnter}
        onClick={openDefault}
        fontSize={'24px'}
        className="shrink-0 cursor-pointer text-resin"
        data-testid="icon"
      />
    </IconContainer>
  );
};

export default LocalizationIndicator;
