import { useDispatch } from 'react-redux';
import CartSnackbar from 'components/organisms/global/snack-bar/cart-snackbar';
import { ICartItem } from 'helpers/types';
import { TProductDetails } from 'helpers/types/organisms/listing/product-details';
import { useAppSelector } from 'redux/hooks';
import { addItemToCart, createCart, removeCartItem, updateCartItemQuantity } from 'redux/reducers/product-cart';
import { RootState } from 'redux/store';

export enum SnackBarMessages {
  ITEM_ADDED_TO_CART = 'Item added to the cart',
  ITEM_UPDATED_IN_CART = 'Item updated in the cart',
  ITEM_REMOVED_FROM_CART = 'Item removed from the cart',
}

export const useCartHandler = (props: TProductDetails, pvid, triggeredFrom) => {
  const dispatch = useDispatch();
  const cart = useAppSelector((state: RootState) => state.cart);
  const { openSnackbarHandler } = CartSnackbar({});
  const existingCart = cart && cart.carts && cart.carts[`${props.storeId}`];
  const existingItems = existingCart?.items || {};
  const existingItem: ICartItem = existingItems[pvid] || { product_id: null, quantity: 0, variant_id: '' };

  const handleCart = async ({
    selectedQuantity,
    setShowSnackBar,
    onClose,
    setSelectedQuantity,
  }: {
    selectedQuantity: number;
    setShowSnackBar: (show: boolean) => void;
    onClose?: () => void;
    setSelectedQuantity: (number: number) => void;
  }) => {
    try {
      let cartData: any = {};
      let snackbarContent = '';

      if (existingCart) {
        cartData = existingCart;
      } else {
        const data = await (dispatch as any)(createCart({ store_id: props.storeId as number }));
        cartData = data.payload;
      }
      if (cartData.items && cartData.items[pvid] && selectedQuantity !== 0) {
        await (dispatch as any)(
          updateCartItemQuantity({
            cartUUID: cartData.cart_uuid,
            pvid: pvid,
            quantity: selectedQuantity,
          }),
        );
        if (onClose) {
          onClose();
        }
        snackbarContent = SnackBarMessages.ITEM_UPDATED_IN_CART;
      } else if (cartData.items[pvid] && selectedQuantity === 0) {
        await (dispatch as any)(
          removeCartItem({
            cartUUID: cartData.cart_uuid,
            pvid: pvid,
          }),
        );
        if (cartData.items.length === 1) {
          dispatch({
            type: 'cart/clearCart',
            payload: {
              store_id: props.storeId,
            },
          });
        }
        setSelectedQuantity(1);
        if (onClose) {
          onClose();
        }
        snackbarContent = SnackBarMessages.ITEM_REMOVED_FROM_CART;
      } else {
        await (dispatch as any)(
          addItemToCart({
            cartUUID: cartData.cart_uuid,
            pvid: pvid,
            quantity: selectedQuantity,
            addCartEvent: {
              placement: props.placement ? props.placement + 1 : 1,
              product_position: props.placement ? props.placement + 1 : 1,
              product_id: `${props.productId}`,
              product_name: `${props.strain}`,
              product_brand: `${props.brand}`,
              product_category: `${props.kind}`,
              product_lineage: props.tags.join(', '),
              product_weight: `${props.defaultWeightSelect}`,
              product_actual_price: `${Boolean(props.price) ? props.price : props.originalPrice}`,
              product_quantity: selectedQuantity,
              product_offer_applied: `${props.offerText}`,
              product_discounted_price: `${Boolean(props.price) ? props.originalPrice : 0}`,
              add_to_cart_trigger: triggeredFrom,
            },
          }),
        );
        if (onClose) {
          onClose();
        }
        snackbarContent = SnackBarMessages.ITEM_ADDED_TO_CART;
      }

      if (cart.error) {
        setShowSnackBar(false);
        return;
      }

      openSnackbarHandler(snackbarContent);
    } catch (error) {
      setShowSnackBar(false);
    }
  };

  return { existingItem, handleCart };
};
