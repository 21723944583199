import { useState } from 'react';
import { Typography } from 'components/atoms/typography';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { TContenfulImageProp } from 'helpers/types';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { getLinkTarget } from 'helpers/utils/common';
import { useAppSelector } from 'redux/hooks';
import { CommonNavigation } from '../../../header-dispensaries/types/common';
import { HeaderDataType } from '../../../header-dispensaries/types/navigation';
import HeaderShopListSection from './header-shop-list-section';
import { HeaderShopListDesktopList, HeaderShopListDesktopWrapper } from './styles';

type TProps = {
  data?: HeaderDataType;
  modalShopData?: CommonNavigation[];
};

const HeaderShopListDesktop = ({ modalShopData, data }: TProps) => {
  const [hover, setHover] = useState<{ name: string; image: TContenfulImageProp } | null>(null);
  const host = useAppSelector((state) => state?.hostReducer?.host);
  const handleOnMouse = (item: any) => {
    setHover(item);
  };

  return (
    <HeaderShopListDesktopWrapper data-testid="header-shop-desktop">
      <HeaderShopListDesktopList>
        {modalShopData?.map((item, index) => (
          <li
            className="flex-inline cursor-pointer overflow-y-hidden"
            onMouseEnter={() =>
              handleOnMouse({
                name: item?.navigationLabel,
                image: item?.navigationBanner,
              })
            }
            key={index}
          >
            {item.isDrawer ? (
              <Typography
                as="a"
                variant="h2"
                className={`inline-block w-full ${
                  hover
                    ? hover?.name.toLowerCase() === item?.navigationLabel.toLowerCase()
                      ? 'opacity-100'
                      : 'opacity-40 hover:opacity-100'
                    : 'opacity-100'
                }`}
              >
                {item?.navigationLabel}
              </Typography>
            ) : (
              <Typography
                as="a"
                target={getLinkTarget(item?.navigationUrl)}
                onClick={() =>
                  navigationTrack(SegmentEvents.NAVIGATION_EXPANDED, {
                    nav_position: 'header',
                    link_primary: item?.navigationLabel,
                    link_type: 'hyperlink',
                  })
                }
                variant="h2"
                href={item?.navigationUrl}
                className={`inline-block w-full ${
                  hover
                    ? hover?.name.toLowerCase() === item?.navigationLabel.toLowerCase()
                      ? 'opacity-100'
                      : 'opacity-40 hover:opacity-100'
                    : 'opacity-100'
                }`}
              >
                {item?.navigationLabel}
              </Typography>
            )}
          </li>
        ))}
      </HeaderShopListDesktopList>
      {hover?.name?.toLowerCase() === THeaderState.CATEGORIES && (
        <HeaderShopListSection
          name={THeaderState.CATEGORIES}
          data={data?.pageHomeCollection?.items?.[0]?.categories?.items?.[0]?.facetsCollection?.items}
          image={hover.image}
          host={host}
          handleOnMouse={handleOnMouse}
        />
      )}
      {hover?.name?.toLowerCase() === THeaderState.BRANDS && (
        <HeaderShopListSection
          name={THeaderState.BRANDS}
          data={data?.pageHomeCollection?.items?.[0]?.brands?.facetsCollection?.items}
          image={hover.image}
          host={host}
          handleOnMouse={handleOnMouse}
          ctaName="See all brands"
        />
      )}
      {hover?.name?.toLowerCase() === THeaderState.USAGES && (
        <HeaderShopListSection
          name={THeaderState.USAGES}
          host={host}
          data={data?.pageHomeCollection?.items?.[0].usages?.items?.[0]?.facetsCollection?.items}
          image={hover.image}
          handleOnMouse={handleOnMouse}
        />
      )}
    </HeaderShopListDesktopWrapper>
  );
};

export default HeaderShopListDesktop;
