'use client';

import { useEffect, useRef } from 'react';

const useIntersectionObserver = (callback?: () => void, options?: any) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= 0.5) {
          callback && callback();
        }
      },
      { threshold: 0.5, ...options },
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback, options]);

  return targetRef;
};

export default useIntersectionObserver;
