import React from 'react';
import Image from 'next/image';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TContenfulImageProp } from 'helpers/types';
import * as screenSizes from 'helpers/utils/screensizes';
import { HeaderImageAnchor, HeaderImageBox } from './styles';

const HeaderImage = ({ url, title }: TContenfulImageProp) => {
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const width = isBiggerThanMobile ? 54.679 : 40;
  const height = isBiggerThanMobile ? 62.428 : 48;
  return (
    <HeaderImageAnchor href="/">
      <HeaderImageBox $h={`h-${height}`}>
        <Image
          data-testid="header-image"
          src={url}
          loader={(options) => options.src}
          alt={title || ''}
          height={height}
          width={width}
          title={title}
          priority
        />
      </HeaderImageBox>
    </HeaderImageAnchor>
  );
};

export default HeaderImage;
