import React from 'react';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useAppSelector } from 'redux/hooks';
import { MenuExtendedType } from '../header-dispensaries/types/menu-extended';
import HeaderCannabisInsiderDesktop from './header-cannabis-insider-desktop';
import HeaderCannabisInsiderMobile from './header-cannabis-insider-mobile';
import { HeaderCannabisInsiderWrapper } from './styles';

type TProps = {
  modal: MenuExtendedType;
  toggleModal: (data: any) => void;
  host?: string;
};

const HeaderCannabisInsider = ({ modal, toggleModal }: TProps) => {
  const host = useAppSelector((state) => state.hostReducer.host);
  const [isDesktop] = useMediaQuery(desktop);

  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_final: name,
      // FIXME: This data model is not consistent with the shop modal
      // link_primary: modal?.cannabisInsider?.data?.name,
      link_primary: modal?.cannabisInsider?.name,
      link_secondary: '',
      link_type: 'hyperlink',
    });
  };

  return (
    <HeaderCannabisInsiderWrapper>
      {isDesktop ? (
        <HeaderCannabisInsiderDesktop data={modal?.cannabisInsider?.data} onPressLink={handleOnPressLink} host={host} />
      ) : (
        <HeaderCannabisInsiderMobile
          host={host}
          data={modal?.cannabisInsider?.data}
          onPressLink={handleOnPressLink}
          toggleModal={toggleModal}
        />
      )}
    </HeaderCannabisInsiderWrapper>
  );
};

export default HeaderCannabisInsider;
