import tw from 'tailwind-styled-components';
type TProps = {
  $isDob?: boolean;
};
export const AgeGateOuterContainer = tw.div`
  w-full
  h-full
  max-h-full
  max-w-screen
  fixed
  top-0
  z-[2147483647]
`;

export const AgeGateContainer = tw.div`
  relative
  w-full
  h-full
  max-h-full
  max-w-screen
  flex 
  flex-col 
  items-start
  md:flex-row
  bg-white
  overflow-hidden
  z-[2147483647]
`;

export const AgeGateWelcomeContainer = tw.div<TProps>`
  bg-kief
  w-full
  h-full 
  max-h-[164px]
  md:max-h-full
  py-3
  flex
  justify-center
  md:w-[513px]
  2xl:justify-start
  2xl:w-[516px]
  flex
  lg:flex-col
  gap-3
  items-center
  md:gap-20
  md:py-[60px]
  md:px-10
  2xl:pt-[188px]
  2xl:pb-0
  2xl:px-[80px]
  ${(p) => (p.$isDob ? ' max-h-[72px] flex-row' : ' max-h-[164px] flex-col ')}
`;

export const AgeGateWelcomeLogo = tw.div`
  w-[50px]
  h-[80px]
  md:hidden
`;

export const AgeGateWelcomeLogoTablet = tw.div`
  hidden
  md:flex
  w-[99px]
  h-[150px]
  2xl:w-[116px]
  2xl:h-[176px]
`;

export const AgeGateWelcomeTextContainer = tw.div`
  w-[190px]
  h-12
  md:w-full
`;

export const AgeGateBodyContainer = tw.div`
  relative
  flex
  justify-center
  w-full
  h-full
  max-w-screen
  min-h-full
  lg:w-full
  2xl:w-full
  mt-0

`;

export const AgeGateBackgroundVerificationAgeContainer = tw.div<TProps>`
  absolute
  w-[287px]
  z-[-1]
  lg:!top-[260px]
  2xl:!top-[140px]
  lg:left-[35%]
  lg:text-[368px]
  lg:leading-[368px]
  ${(p) => (p.$isDob ? ' !top-[-100px]' : ' !top-0')}
`;

export const AgeGateInnerBodyContainer = tw.div<TProps>`
 relative
 flex 
 flex-col 
 lg:justify-center
 items-center 
 self-stretch  
 pb-6 
 px-5
 w-full
lg:w-[687px]
2xl:w-full
gap-12
 ${(p) =>
   p.$isDob
     ? ' top-0  pt-[63px] lg:top-[8%] 2xl:top-[-10%] lg:gap-[80px] 2xl:gap-[120px]'
     : '  top-[8%]  pt-[88px] lg:top-[4%] 2xl:top-[-12%] lg:gap-[120px] 2xl:gap-[224px]'}
`;

export const AgeGateVerificationContainer = tw.div`
  flex 
  flex-col
  gap-6 
  lg:gap-10 
  text-center
  h-[88px]
  lg:h-auto
`;

export const AgeGateTermsContainer = tw.div<TProps>`
  w-full
  h-auto
  h-[184px]
  flex
  flex-col
  gap-5
  lg:gap-[42px]
  2xl:gap-[72px]
  lg:w-[687px]
  2xl:w-[670px]
  2xl:h-[300px]
  items-center
  ${(p) => (p.$isDob ? 'mt-3' : 'mt-0')}
`;

export const AgeGateTermsButtonRow = tw.div`
  flex
  flex-row
  w-full
  h-20
  justify-center
  gap-2.5
`;

export const AgeGateTermsButtonContainer = tw.div`
  w-[150px]
  lg:w-[150px]
  lg:h-[88px]
  h-10
  2xl:w-[225px]
  2xl:h-32
`;

export const AgeGateButtonStyleOverrides = `
  h-full
  w-full
  !rounded-[102px]
  lg:!rounded-[200px]
  !font-arial
  !text-sm 
  !not-italic 
  !font-bold 
  !leading-4 
  !tracking-[0.14px]
  uppercase
  2xl:!text-[42px]
  2xl:!font-medium 
  2xl:!leading-10 
  2xl:!tracking-[0.84px]
  2xl:!font-antarctican
  lg:!text-[32px] 
  lg:!font-medium 
  lg:!leading-[30px] 
  lg:!tracking-[0.64px]
  lg:!font-antarctican
`;
