import { FormikProps, FormikValues } from 'formik';
import { SelectDefaultProps, SelectDropdown } from 'components/atoms/inputs/select';
interface FormikSelectProps {
  fieldName?: string;
  value?: string;
  values: FormikValues;
  errors?: any;
  touched?: any;
  setFieldValue?: (field: string, value: string) => any;
  setFieldTouched?: (field: string, value: boolean) => any;
}

export const FormikSelect = ({
  fieldName,
  label,
  items,
  value,
  setFieldValue,
  onChange,
  setFieldTouched,
  errors,
  touched,
  values,
  required,
  disabled,
  ...props
}: FormikSelectProps & SelectDefaultProps) => {
  const invalid = errors[fieldName as string] && touched[fieldName as string] && values[fieldName as string] === '';

  return (
    <SelectDropdown
      label={label}
      required={required}
      disabled={disabled}
      items={items}
      defaultValue={values[fieldName as string] || value}
      onChange={(value) => {
        setFieldValue?.(fieldName as string, value);
        onChange?.(value);
        if (!touched[fieldName as string]) {
          setFieldTouched && setFieldTouched(fieldName as string, true);
        }
      }}
      error={invalid ? true : false}
      errorMessage={errors[fieldName as string]}
      {...props}
    />
  );
};

export const SelectStateDropdown = (
  props: FormikProps<SelectDefaultProps & FormikSelectProps> & SelectDefaultProps & FormikSelectProps,
) => {
  return <FormikSelect label="Select state" fieldName="state" {...props} />;
};

export const SelectDispensaryDropdown = (
  props: FormikProps<SelectDefaultProps & FormikSelectProps> & SelectDefaultProps & FormikSelectProps,
) => {
  return <FormikSelect label="Select dispensary" fieldName="dispensary" {...props} />;
};
