import React, { ButtonHTMLAttributes } from 'react';
import { usePageType } from 'helpers/hooks/usePageType';
import { AnchorElement, TAdditionalContext } from 'helpers/types';
import { MemoizedAutoIdFromAdditionalContext, ReplaceUrlWithHost } from 'helpers/utils/common';
import { buttonCss } from '../styles';

export type Color = 'white' | 'green';
export type ButtonType = 'primary' | 'secondary' | 'tertiary';
export type Size = 'sm' | 'lg';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: Color;
  'button-type': ButtonType;
  size?: Size;
  as?: 'button';
  'full-width'?: boolean;
  additionalContext?: TAdditionalContext;
}

export interface IAnchor extends AnchorElement {
  color: Color;
  'button-type': ButtonType;
  size?: Size;
  as?: 'a';
  href: string;
  host?: string; // At this point of time. host is optional from breaking other components. TODO: remove this option when other component are fixed with host.
  disabled?: boolean;
  'full-width'?: boolean;
  additionalContext?: TAdditionalContext;
}

export type IButtonProps = IAnchor | IButton;

export const CommonButton: React.FC<IButtonProps> = (props) => {
  const pageType = usePageType();
  const { color, size = 'lg', disabled } = props;
  const parsedHost = (props as IAnchor).host;
  const cssClasses = `${buttonCss(props['button-type'], size, color, disabled)} ${props.className}`.replace(
    /\s\s+/g,
    ' ',
  );
  const cssWidthFullWidth = props['full-width'] ? cssClasses.concat(' w-full inline-block text-center') : cssClasses;

  return props.as === 'a' ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      {...props}
      href={props?.host ? (props?.href ? ReplaceUrlWithHost(props.href, props?.host) : '/') : props.href || '/'}
      className={`${cssWidthFullWidth} ${disabled ? ' pointer-events-none' : ''}`}
      data-testid={(props.additionalContext && props.additionalContext.testId) || props['data-testid'] || null}
      data-automationid={MemoizedAutoIdFromAdditionalContext(
        props.additionalContext as TAdditionalContext,
        pageType as string,
      )}
      target={props.target && parsedHost && !props.href?.includes?.(parsedHost) ? '_blank' : '_self'}
      rel={parsedHost && !props.href?.includes?.(parsedHost) ? 'nofollow' : 'opener'}
    >
      {props.children}
    </a>
  ) : (
    <button
      {...(props as ButtonHTMLAttributes<HTMLButtonElement>)} // Add type assertion to specify the props as ButtonHTMLAttributes<HTMLButtonElement>
      className={cssWidthFullWidth}
      data-testid={(props.additionalContext && props.additionalContext.testId) || props['data-testid'] || null}
      data-automationid={MemoizedAutoIdFromAdditionalContext(
        props.additionalContext as TAdditionalContext,
        pageType as string,
      )}
    >
      {props.children}
    </button>
  );
};
