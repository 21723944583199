'use client';

import { useEffect, useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
interface FilterPillButtonProps {
  label: string;
  name?: string;
  icon?: React.ReactNode;
  defaultSelected?: boolean;
  className?: string;
  onClick?: (select: { name: string; value: boolean }) => void;
  disabled?: boolean;
  dataTestTd?: string;
}

export const FilterPillButton = ({
  label = 'Label (10)',
  icon,
  name,
  onClick,
  disabled,
  defaultSelected = false,
  dataTestTd,
}: FilterPillButtonProps) => {
  const [selected, setSelected] = useState(defaultSelected || false);

  const onSelect = () => {
    const newState = !selected;
    setSelected(newState);
    if (onClick) onClick({ name: name || label, value: newState });
  };

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const activedStyle = selected
    ? `
      !bg-leaf
      !font-bold
      !text-white
      !border-none
      [&_svg_path]:!fill-grey-200
    `
    : `
      !border-grey-400/80 
      font-normal
      !text-grey-400
      [&_svg_path]:!fill-grey-400/80
      `;

  const sizeStyle = icon ? '!p-[8px_20px_8px_18px] md:!p-[14px_24px_14px_20px]' : '!p-[12px_20px]  md:!p-[14px_24px]';
  const currentStyle = disabled ? '' : activedStyle;

  return (
    <>
      {icon ? (
        <IconWithTextButton
          iconPosition="before"
          color="green"
          button-type="secondary"
          className={`h-10 !gap-2 md:h-[42px] md:!gap-[6px] xl:h-12 xl:!gap-2 ${sizeStyle} ${currentStyle}`}
          onClick={onSelect}
          icon={icon}
          disabled={disabled}
          data-testid={dataTestTd}
        >
          {label}
        </IconWithTextButton>
      ) : (
        <CommonButton
          color="green"
          button-type="secondary"
          className={`h-10 md:h-[42px] xl:h-12 ${sizeStyle} ${currentStyle}`}
          onClick={onSelect}
          disabled={disabled}
          data-testid={dataTestTd}
        >
          {label}
        </CommonButton>
      )}
    </>
  );
};
